import { DocNode } from "../docs/types";
import { makeExample } from "../docs/utils";
import { SceneElement } from "../types";

export const ignore = (..._args: unknown[]): SceneElement => ({
  type: "ignore",
  props: {},
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
all descendants will be absent from the scene.
`;

const example1 = makeExample(`
;; Working with a scene and need to
;; temporarily hide some geometries?
(group {}
  (box { W: 2, H: 1, L: 2 })
  (box { W: 1, H: 2, L: 1 }))

;; Replace group with ignore
(ignore {}
  ;; Geometries will be absent from the scene
  (box { W: 2, H: 1, L: 2})
  (box { W: 1, H: 2, L: 1 }))
`);

export const IgnoreDocumentation: DocNode = {
  name: "ignore",
  description,
  arguments: ["props", "children"],
  tags: ["geometry", "composition"],
  examples: [example1],
};
