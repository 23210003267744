import { css } from "@emotion/react";
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { useSetAtom } from "jotai";
import { FC, useCallback } from "react";
import { createAssemblyCommand } from "../../state/assembliesState";

const containerCss = css`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: start;
`;

export const CreateAssemblyButton: FC = () => {
  const createAssembly = useSetAtom(createAssemblyCommand);
  const onClick = useCallback(() => {
    const name = window.prompt("New assembly name", "");

    if (name) {
      createAssembly(name);
    }
  }, [createAssembly]);

  return (
    <button type="button" data-borderless css={containerCss} onClick={onClick}>
      <Icon path={mdiPlus} size={0.7} />
      Create assembly
    </button>
  );
};
