import SibilantDocumentation from "sibilant/docs/docs.json";
import { AnglesDocumentation } from "../preamble/docs/angles";
import { DocNode } from "./types";
import { BoxDocumentation } from "../preamble/box";
import { SphereDocumentation } from "../preamble/sphere";
import { ConeDocumentation } from "../preamble/cone";
import { CylinderDocumentation } from "../preamble/cylinder";
import { MeshDocumentation } from "../preamble/mesh";
import { GroupDocumentation } from "../preamble/group";
import { PositionDocumentation } from "../preamble/position";
import { RotationDocumentation } from "../preamble/rotation";
import { ScaleDocumentation } from "../preamble/scale";
import { IgnoreDocumentation } from "../preamble/ignore";
import { ColorsDocumentation } from "../preamble/docs/colors";
import { GeometryDocumentation } from "../preamble/geometry";
import { BaseDocumentation } from "../preamble/base";
import { AdditionDocumentation } from "../preamble/addition";
import { SubtractionDocumentation } from "../preamble/subtraction";
import { IntersectionDocumentation } from "../preamble/intersection";
import { DifferenceDocumentation } from "../preamble/difference";
import { FractionsDocumentation } from "../preamble/docs/fractions";
import { LatheDocumentation } from "../preamble/lathe";
import { ExtrudeDocumentation } from "../preamble/extrude";
import { CapsuleDocumentation } from "../preamble/capsule";
import { TorusDocumentation } from "../preamble/torus";

export const AllDocumentation: DocNode[] = [
  // Evaluation
  AnglesDocumentation,
  FractionsDocumentation,
  ColorsDocumentation,
  // Containers and helpers
  MeshDocumentation,
  GroupDocumentation,
  PositionDocumentation,
  RotationDocumentation,
  ScaleDocumentation,
  IgnoreDocumentation,
  // Constructive solid geometry
  GeometryDocumentation,
  BaseDocumentation,
  AdditionDocumentation,
  SubtractionDocumentation,
  IntersectionDocumentation,
  DifferenceDocumentation,
  // Primitives
  BoxDocumentation,
  SphereDocumentation,
  CylinderDocumentation,
  ConeDocumentation,
  LatheDocumentation,
  ExtrudeDocumentation,
  CapsuleDocumentation,
  TorusDocumentation,
  ...SibilantDocumentation,
];
