import { atom } from "jotai";
import { isError, isString } from "lodash";

import { Evaluated, EvaluatorLogs } from "../types";
import evalCode from "../utils/evalCode";
import PREAMBLE from "../preamble";
import { atomFamily } from "jotai/utils";
import { transpiledAssemblyState } from "./transpiledAssemblyState";
import { assemblyIdByNameState } from "./assemblyIdByNameState";

export const evaluatedAssemblyState = atomFamily((assemblyId: string) =>
  atom<Evaluated>((get) => {
    const transpiled = get(transpiledAssemblyState(assemblyId));
    const logs: EvaluatorLogs = [];

    if (transpiled.status === "success") {
      try {
        const log = (...args: unknown[]) => {
          console.log(...args);
          logs.push({ level: "log", args });
        };

        const useAssembly = (name: string) => {
          logs.push({
            level: "debug",
            args: [`(use-assembly "${name}") evaluating...`],
          });

          const useTarget = get(assemblyIdByNameState(name));

          if (!useTarget) {
            logs.push({
              level: "error",
              args: [`Failed to use assembly ${name}`],
            });

            return undefined;
          }

          const targetResult = get(evaluatedAssemblyState(useTarget.id));

          logs.push(...targetResult.logs);

          if (targetResult.status === "failure") {
            logs.push({
              level: "error",
              args: [`(use-assembly "${name})" failed to evaluate`],
            });
            return undefined;
          }

          logs.push({
            level: "debug",
            args: [`(use-assembly "${name})" evaluated successfully`],
          });

          return targetResult.output;
        };

        const output = evalCode(transpiled.output, {
          ...PREAMBLE,
          log,
          useAssembly,
        });

        // Some evaluation outputs are unexpected and will
        // therefore be considered as a failure.
        if (isString(output)) {
          return {
            error: `Unhandled result type: ${typeof output}`,
            status: "failure",
            logs: [],
          };
        }

        return { output, status: "success", logs };
      } catch (error: unknown) {
        if (isError(error)) {
          return { error: error.message, status: "failure", logs };
        }

        return { error: "Unknown error", status: "failure", logs };
      }
    }

    return {
      status: "failure",
      error: "Unable to evaluate: transpilation failed.",
      logs: [{ level: "error", args: [transpiled.error.toString()] }],
    };
  })
);
