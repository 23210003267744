import { useAtomValue } from "jotai";
import { FC } from "react";
import { assembliesState } from "../../state/assembliesState";
import { css } from "@emotion/react";
import { AssemblyListItem } from "./AssemblyListItem";
import { CreateAssemblyButton } from "./CreateAssemblyButton";

const containerCss = css`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  gap: 3px;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
`;

export const Assemblies: FC = () => {
  const assemblies = useAtomValue(assembliesState);

  return (
    <div css={containerCss}>
      <CreateAssemblyButton key="create" />
      {assemblies.map((assembly) => (
        <AssemblyListItem key={assembly.id} assembly={assembly} />
      ))}
    </div>
  );
};
