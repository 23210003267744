import {
  ComponentType,
  FC,
  Fragment,
  useCallback,
  useContext,
  useRef,
} from "react";
import { Viewport } from "../Viewport/Viewport";
import { css } from "@emotion/react";
import {
  Mosaic,
  MosaicBranch,
  MosaicContext,
  MosaicWindow,
  MosaicWindowContext,
  MosaicWindowProps,
} from "react-mosaic-component";
import "react-mosaic-component/react-mosaic-component.css";
import { TranspiledOutput } from "../TranspiledOutput";
import { EvaluatedOutput } from "../EvaluatedOutput";
import { Documentation } from "../Documentation";
import { LayoutNode, MosaicWindowKey } from "./types";
import { isDraggingState, layoutState } from "./state";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { ColorPalette } from "../ColorPalette";
import { MonacoEditor } from "../MonacoEditor/MonacoEditor";
import { getViewTitle } from "../../utils/getViewTitle";
import { ErrorBoundary } from "../ErrorBoundary";
import { activeAssemblyNameState } from "../../state/activeAssemblyNameState";
import { Assemblies } from "../Assemblies";
import { EvaluatorLogsDisplay } from "../EvaluatorLogsDisplay";
import { layoutCss } from "./styles";

const VIEWS: Record<MosaicWindowKey, ComponentType> = {
  viewport: Viewport,
  editor: MonacoEditor,
  transpiled: TranspiledOutput,
  evaluated: EvaluatedOutput,
  documentation: Documentation,
  colors: ColorPalette,
  assemblies: Assemblies,
  "evaluator-logs": EvaluatorLogsDisplay,
};

const toolbarLayoutCss = css`
  padding: 5px 10px;
  font-size: 14px;
`;

const Toolbar: FC<MosaicWindowProps<MosaicWindowKey>> = (props) => {
  const { mosaicActions } = useContext(MosaicContext);
  const { mosaicWindowActions } = useContext(MosaicWindowContext);

  const onClose = () => {
    const path = mosaicWindowActions.getPath();
    mosaicActions.remove(path);
  };

  const activeAssemblyName = useAtomValue(activeAssemblyNameState);

  const viewTitle =
    props.title === "editor"
      ? activeAssemblyName ?? "Editor"
      : getViewTitle(props.title);

  return (
    <Fragment>
      <div css={toolbarLayoutCss}>
        <strong data-color={400}>{viewTitle}</strong>
      </div>

      <button data-borderless type="button" onClick={onClose}>
        <Icon path={mdiClose} size={0.6} />
      </button>
    </Fragment>
  );
};

const renderToolbar = (props: MosaicWindowProps<MosaicWindowKey>) => (
  <div className="mosaic-window-toolbar-layout">
    <Toolbar {...props} />
  </div>
);

const RESIZE = {
  minimumPaneSizePercentage: 20,
};

export const Layout: FC = () => {
  const layout = useAtomValue(layoutState);
  const layoutUpdateRef = useRef<LayoutNode | null>(null);

  const onChange = useAtomCallback(
    useCallback((get, set, update: LayoutNode | null) => {
      layoutUpdateRef.current = update;

      const isDragging = get(isDraggingState);
      if (!isDragging) {
        // @ts-expect-error
        set(layoutState, update);
      }
    }, [])
  );

  const onDragStart = useAtomCallback(
    useCallback((_get, set) => {
      set(isDraggingState, true);
    }, [])
  );

  const onDragEnd = useAtomCallback(
    useCallback((_get, set) => {
      set(isDraggingState, false);

      const update = layoutUpdateRef.current;
      if (update) {
        set(layoutState, update);
      }
    }, [])
  );

  const renderTile = useCallback(
    (id: MosaicWindowKey, path: MosaicBranch[]) => {
      const Component = VIEWS[id];

      return (
        <MosaicWindow
          draggable
          title={id}
          path={path}
          renderToolbar={renderToolbar}
          createNode={() => id}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        >
          <ErrorBoundary>
            <Component />
          </ErrorBoundary>
        </MosaicWindow>
      );
    },
    [onDragStart, onDragEnd]
  );

  return (
    <div css={layoutCss}>
      <Mosaic<MosaicWindowKey>
        mosaicId="ffuzion-cad-layout"
        className=""
        renderTile={renderTile}
        resize={RESIZE}
        value={layout}
        onChange={onChange}
      />
    </div>
  );
};
