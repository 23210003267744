import { isArray, isEmpty, isObject, size } from "lodash";
import { ObjectRootLabel, ObjectLabel } from "react-inspector";

type NodeRendererProps = {
  depth: number;
  name: string;
  data: any;
  isNonenumerable: boolean;
  expanded: boolean;
};

export const nodeRenderer = ({
  depth,
  name,
  data,
  isNonenumerable,
  expanded,
}: NodeRendererProps) => {
  if (depth === 0) {
    return <ObjectRootLabel name={name} data={data} />;
  }

  if (isArray(data) && name === "children") {
    return "children;";
  }

  if (isObject(data) && name === "props") {
    return isEmpty(data) ? "props (empty)" : "props";
  }

  if (isObject(data) && "type" in data) {
    return "shape";
  }

  if (isArray(data) && size(data) === 3) {
    return name + " [" + data.join(",") + "]";
  }

  return (
    <ObjectLabel name={name} data={data} isNonenumerable={isNonenumerable} />
  );
};
