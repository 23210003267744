import { css } from "@emotion/react";
import { FC } from "react";

import { Contents } from "./Contents";
import { Search } from "./Search";

const containerCss = css`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const layoutCss = css`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex: 1 1 auto;
`;

export const Documentation: FC = () => {
  return (
    <div css={containerCss}>
      <Search />
      <div css={layoutCss}>
        <Contents />
      </div>
    </div>
  );
};
