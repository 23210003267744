import { atom } from "jotai";
import { assembliesState } from "./assembliesState";

export const renameAssemblyCommand = atom(
  null,
  (_get, set, idToRename: string, name: string) => {
    set(assembliesState, (assemblies) =>
      assemblies.map((assembly) =>
        assembly.id !== idToRename ? assembly : { ...assembly, name }
      )
    );
  }
);
