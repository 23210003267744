import { atom } from "jotai";

export const shadingModeState = atom<"flat" | "smooth">("smooth");

export const toggleShadingModeCommand = atom(null, (get, set) => {
  const current = get(shadingModeState);
  set(shadingModeState, current === "smooth" ? "flat" : "smooth");
});

export const smoothShadingEnabledState = atom(
  (get) => get(shadingModeState) === "smooth",
  (_get, set, enabled: boolean) => {
    set(shadingModeState, enabled ? "smooth" : "flat");
  }
);
