import React from "react";
import { css } from "@emotion/react";
import { Layout } from "./components/Layout";
import { TopNav } from "./components/TopNav";

const pageLayoutCss = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

function App() {
  return (
    <div css={pageLayoutCss}>
      <TopNav />
      <Layout />
    </div>
  );
}

export default App;
