import { FC } from "react";
import colors from "../../styles/colors";
import { map } from "lodash";
import { css } from "@emotion/react";

const containerCss = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  max-height: 100%;
  padding: 10px 20px 10px 10px;
  box-sizing: border-box;
`;

const colorContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const swatchesContainerCss = css`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const swatchCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 12px;
`;

const SIZE = 25;

const valueCss = css`
  width: ${SIZE}px;
  height: ${SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
`;

export const ColorPalette: FC = () => (
  <div css={containerCss}>
    {map(colors, (color, name) => (
      <div key={name} css={colorContainerCss}>
        <strong>{name}</strong>
        <div css={swatchesContainerCss}>
          {map(color, (hex, value) => (
            <div css={swatchCss}>
              <div
                key={value}
                css={valueCss}
                style={{ backgroundColor: hex }}
              />
              {value}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);
