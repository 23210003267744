import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { CylinderElementProps, CylinderProps, SceneElement } from "../types";

const processProps = (props: CylinderProps = {}): CylinderElementProps => ({
  ...props,
  args: [
    props.Rtop ?? props.R ?? props.args?.[0],
    props.Rbottom ?? props.R ?? props.args?.[0],
    props.H ?? props.args?.[1],
    props.segments ?? props?.args?.[3],
  ],
});

export const cylinder = (props: CylinderProps): SceneElement => ({
  type: "cylinder",
  props: processProps(props),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a cylinder geometry.
`;

const example1 = makeExample(`
(cylinder { R: 1, H: 1, color: 'indigo500 })
`);

const example2 = makeExample(`
(cylinder { Rtop: 1, Rbottom: 2, H: 3, color: 'indigo500 })
`);

export const CylinderDocumentation: DocNode = {
  name: "cylinder",
  description,
  arguments: [`props`],
  props: findPropsDeclaration("CylinderProps"),
  tags: ["geometry", "primitive"],
  examples: [example1, example2],
};
