import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { filter, flatMap, isEmpty, uniq, without } from "lodash";
import { DocNode } from "../../docs/types";
import { AllDocumentation } from "../../docs";

const getTags = (docs: DocNode[]) =>
  uniq(flatMap(docs, ({ tags }) => tags)).sort();

export const documentationState = atom<DocNode[]>(() => AllDocumentation);

export const tagsState = atom<string[]>((get) =>
  getTags(get(documentationState))
);

export const tagFiltersState = atom<string[]>([]);

export const tagFilterState = atomFamily((tag: string) =>
  atom(
    (get) => {
      return get(tagFiltersState).includes(tag);
    },
    (_get, set, update: boolean) => {
      if (update) {
        set(tagFiltersState, (tags) => uniq([...tags, tag]));
      } else {
        set(tagFiltersState, (tags) => without(tags, tag));
      }
    }
  )
);

export const queryState = atom<string>("");

export const filteredDocumentationState = atom<DocNode[]>((get) => {
  const docNodes = get(documentationState);
  const tagFilters = get(tagFiltersState);
  const query = get(queryState);

  if (isEmpty(tagFilters) && isEmpty(query)) {
    return docNodes;
  }

  return filter(
    docNodes,
    (docNode) =>
      (isEmpty(tagFilters) ||
        docNode.tags.some((tag) => tagFilters.includes(tag))) &&
      JSON.stringify(docNode).includes(query)
  );
});

export const areTagsVisibleState = atom(false);

export const areTagFiltersActiveState = atom((get) => {
  const selectedTags = get(tagFiltersState);
  return !isEmpty(selectedTags);
});
