import { css } from "@emotion/react";
import { FC, memo } from "react";
import { BASE_COLOR } from "../../styles/globals";
import { Inspector } from "react-inspector";
import { filteredDocumentationState } from "./state";
import { useAtomValue } from "jotai";
import { DocNode } from "../../docs/types";
import { Pill } from "../controls/Pill";
import { humanize } from "../../utils/humanize";
import Markdown from "markdown-to-jsx";
import { isEmpty } from "lodash";
import { ContentExample } from "./ContentExample";

const containerCss = css`
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const layoutCss = css`
  border-radius: 5px;
  border: 1px solid ${BASE_COLOR[600]};
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const propDeclarationCss = css`
  margin-left: 10px;
  padding-left: 10px;
  text-indent: -10px;
`;

const examplesCss = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Content: FC<{ docNode: DocNode }> = memo(({ docNode }) => (
  <div css={layoutCss}>
    <div>
      <strong data-code data-accent-color={300}>
        {docNode.name}
      </strong>
      &nbsp;
      {docNode.tags.map((tag) => (
        <Pill key={tag}>{humanize(tag)}</Pill>
      ))}
    </div>

    <div>
      <small data-strong data-color={400}>
        arguments
      </small>
      <div data-code data-accent-color={300}>
        <small>
          {isEmpty(docNode.arguments) ? (
            <em>none</em>
          ) : (
            docNode.arguments.join(", ")
          )}
        </small>
      </div>
    </div>

    {docNode.props && !isEmpty(docNode.props) && (
      <div>
        <small data-strong data-color={400}>
          props
        </small>
        <div data-code data-accent-color={300}>
          {docNode.props.map((prop) => (
            <div key={prop.name} css={propDeclarationCss}>
              <small>
                {prop.name}
                {prop.isOptional ? "?" : ""}: {prop.type}
              </small>
            </div>
          ))}
        </div>
      </div>
    )}

    <div>
      <small data-strong data-color={400}>
        description
      </small>
      <div>
        <Markdown>{docNode.description}</Markdown>
      </div>
    </div>

    <div>
      <small data-strong data-color={400}>
        examples
      </small>
      <div css={examplesCss}>
        {docNode.examples.map(({ sibilant }, index) => (
          <ContentExample key={index}>{sibilant}</ContentExample>
        ))}
      </div>
    </div>
  </div>
));

const DEBUG = false;

export const Contents: FC = () => {
  const documentation = useAtomValue(filteredDocumentationState);

  return (
    <div css={containerCss}>
      {DEBUG && (
        <Inspector table={false} theme="chromeDark" data={documentation} />
      )}
      {documentation.map((docNode) => (
        <Content key={docNode.name} docNode={docNode} />
      ))}
    </div>
  );
};
