import { useCallback } from "react";
import { saveAs } from "file-saver";
import { getSceneRootRef } from "./state";
import { STLExporter } from "./STLExporter";
import { useAtomValue } from "jotai";
import { activeAssemblyNameState } from "../state/activeAssemblyNameState";
import { getCurrentDateTime } from "../utils/getCurrentDateTime";

export const useExportToSTL = () => {
  const activeAssemblyName = useAtomValue(activeAssemblyNameState);

  return useCallback(() => {
    const sceneRootRef = getSceneRootRef();

    if (!sceneRootRef?.current) {
      alert("No root mesh found");
      return;
    }

    const exported = new STLExporter().parse(sceneRootRef.current, {});
    const blob = new Blob([exported], {
      type: "text/plain;charset=utf-8",
    });

    const filename = `${activeAssemblyName}-${getCurrentDateTime()}.stl`;

    saveAs(blob, filename);
  }, [activeAssemblyName]);
};
