import { atom } from "jotai";
import { Unit } from "../types";
import { ChangeEvent } from "react";
import { UNITS } from "../constants";

export const unitsState = atom<Unit>("mm");

export const onChangeUnitsCommand = atom(
  null,
  (get, set, event: ChangeEvent<HTMLSelectElement>) => {
    const update = event.target.value as Unit;
    console.log({ update });
    set(unitsState, update);
  }
);

export const cameraConfigurationState = atom((get) => {
  const unit = get(unitsState);
  return UNITS[unit].camera;
});

export const gridConfigurationState = atom((get) => {
  const unit = get(unitsState);
  return UNITS[unit].grid;
});
