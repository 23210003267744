import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { SceneElement, TorusElementProps, TorusProps } from "../types";

const processProps = (props: TorusProps): TorusElementProps => ({
  ...props,
  args: [
    props.R ?? props.args?.[0],
    props.Rtube ?? props.args?.[1],
    props.radialSegments ?? props.args?.[2],
    props.tubeSegments ?? props.args?.[3],
  ],
});

export const torus = (props: TorusProps): SceneElement => ({
  type: "torus",
  props: processProps(props),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a torus geometry.
`;

const example1 = makeExample(`
(torus { R: 5, Rtube: 1 })
`);

export const TorusDocumentation: DocNode = {
  name: "torus",
  description,
  arguments: ["props"],
  props: findPropsDeclaration("TorusProps"),
  tags: ["geometry", "primitive"],
  examples: [example1],
};
