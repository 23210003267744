import { css } from "@emotion/react";
import { FC } from "react";

import { Toggle } from "../controls/Toggle";
import { tagFilterState, tagsState } from "./state";
import { useAtomValue } from "jotai";
import { humanize } from "../../utils/humanize";

const containerCss = css`
  padding: 5px;
  flex-shrink: 0;
  min-width: 150px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const tagCss = css`
  padding: 5px;
`;

interface TagProps {
  tag: string;
}

const Tag: FC<TagProps> = ({ tag }) => (
  <div css={tagCss}>
    <Toggle label={humanize(tag)} state={tagFilterState(tag)} />
  </div>
);

export const Tags = () => {
  const tags = useAtomValue(tagsState);

  return (
    <div css={containerCss}>
      {tags.map((tag) => (
        <Tag key={tag} tag={tag} />
      ))}
    </div>
  );
};
