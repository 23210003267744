import { FC } from "react";
import { Measure } from "../../types";
import { Html, Line } from "@react-three/drei";
import { css } from "@emotion/react";
import { BASE_COLOR } from "../../styles/globals";
import { includes } from "lodash";

interface Props {
  l: number | undefined;
  w: number | undefined;
  h: number | undefined;

  measure: Measure;
}

const measurementContainerCss = css`
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const measurementCss = css`
  pointer-events: none;
  font-family: monospace;
  position: absolute;
  text-shadow: 0 0 2px ${BASE_COLOR[900]};
  color: ${BASE_COLOR[50]};
`;

const OFFSET = 0.55;
const RULER_END_SIZE = 0.05;
const RULER_GAP_FACTOR = 12;
const RULER_COLOR = BASE_COLOR[400];

const MeasurementValue: FC<{ value: number }> = ({ value }) => (
  <Html>
    <div css={measurementContainerCss}>
      <div css={measurementCss}>{value}</div>
    </div>
  </Html>
);

const MeasurementRuler: FC<{ value: number }> = ({ value }) => (
  <group>
    <Line
      points={[
        [0, -value / 2, 0],
        [0, -value / RULER_GAP_FACTOR, 0],
      ]}
      color={RULER_COLOR}
      lineWidth={2}
    />

    <Line
      points={[
        [0, value / RULER_GAP_FACTOR, 0],
        [0, value / 2, 0],
      ]}
      color={RULER_COLOR}
      lineWidth={2}
    />

    <Line
      points={[
        [RULER_END_SIZE, -value / 2, 0],
        [-RULER_END_SIZE, -value / 2, 0],
      ]}
      color={RULER_COLOR}
      lineWidth={2}
    />

    <Line
      points={[
        [RULER_END_SIZE, value / 2, 0],
        [-RULER_END_SIZE, value / 2, 0],
      ]}
      color={RULER_COLOR}
      lineWidth={2}
    />
  </group>
);

export const Measurements: FC<Props> = ({ l = 1, w = 1, h = 1, measure }) => {
  if (!measure) {
    return null;
  }

  const widthMeasurement = (measure === true || includes(measure, "W")) && (
    <mesh position={[0, -h * 0.5, l * OFFSET]}>
      <MeasurementValue value={w} />
      <mesh rotation={[Math.PI / 2, 0, Math.PI / 2]}>
        <MeasurementRuler value={w} />
      </mesh>
    </mesh>
  );

  const lengthMeasurement = (measure === true || includes(measure, "L")) && (
    <mesh position={[w * OFFSET, -h * 0.5, 0]}>
      <MeasurementValue value={l} />
      <mesh rotation={[Math.PI / 2, 0, 0]}>
        <MeasurementRuler value={l} />
      </mesh>
    </mesh>
  );

  const heightMeasurement = (measure === true || includes(measure, "H")) && (
    <mesh position={[w * OFFSET, 0, -l * 0.5]}>
      <MeasurementValue value={h} />
      <MeasurementRuler value={h} />
    </mesh>
  );

  return (
    <group>
      {widthMeasurement}
      {lengthMeasurement}
      {heightMeasurement}
    </group>
  );
};
