import { atom } from "jotai";

import { Evaluated } from "../types";
import { activeAssemblyIdState } from "./activeAssemblyIdState";
import { evaluatedAssemblyState } from "./evaluatedAssemblyState";

export const evaluatedState = atom<Evaluated>((get) => {
  const activeAssemblyId = get(activeAssemblyIdState);
  return get(evaluatedAssemblyState(activeAssemblyId));
});
