import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { SceneElement, SphereElementProps, SphereProps } from "../types";

const processProps = (props: SphereProps): SphereElementProps => ({
  ...props,
  args: [props.R ?? props.args?.[0]],
});

export const sphere = (props: SphereProps): SceneElement => ({
  type: "sphere",
  props: processProps(props),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a sphere geometry.
`;

const example1 = makeExample(`
(sphere { L: 1, W: 1, H: 2 })
`);

export const SphereDocumentation: DocNode = {
  name: "sphere",
  description,
  arguments: ["props"],
  props: findPropsDeclaration("SphereProps"),
  tags: ["geometry", "primitive"],
  examples: [example1],
};
