import { css } from "@emotion/react";
import { BASE_COLOR } from "../../styles/globals";
import { transition } from "../../styles/transition";

export const layoutCss = css`
  height: 100%;
  width: 100%;

  .mosaic[class] {
    background-color: ${BASE_COLOR[900]};

    .mosaic-root {
      background-color: ${BASE_COLOR[900]};
    }

    .mosaic-split {
      background-color: ${BASE_COLOR[900]};
    }

    .mosaic-split .mosaic-split-line {
      box-shadow: 0 0 0 1px transparent;
      transition: ${transition("box-shadow")};
    }

    .mosaic-split:hover .mosaic-split-line {
      box-shadow: 0 0 0 1px ${BASE_COLOR[500]};
    }

    .mosaic-window[class] {
      border-radius: 6px;
      background-color: ${BASE_COLOR[900]};
    }

    .mosaic-window-toolbar[class] {
      color: ${BASE_COLOR[300]};
      background-color: ${BASE_COLOR[900]};
      height: min-content;
      transition: ${transition("background")};

      .mosaic-window-toolbar-layout {
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid ${BASE_COLOR[400]};
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          min-width: 24px;
          min-height: 24px;
          padding: 4px;
        }
      }

      &.draggable:hover {
        background: ${BASE_COLOR[800]};

        .mosaic-window-title {
          color: ${BASE_COLOR[300]};
        }
      }

      .mosaic-window-title {
        color: ${BASE_COLOR[300]};
      }
    }

    .mosaic-window-body[class] {
      background-color: ${BASE_COLOR[900]};
      overflow: hidden;
    }

    .mosaic-tile {
      background-color: ${BASE_COLOR[900]};
      border: 1px solid ${BASE_COLOR[400]};
      border-radius: 5px;
    }
  }
`;
