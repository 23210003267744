import { Assembly } from "../types";
import { DEFAULT_ASSEMBLIES } from "./constants";
import { persistentAtom } from "../utils/persistentAtom";
import { atom } from "jotai";
import { uuid4 } from "../utils/uuid4";

export const assembliesState = persistentAtom<Assembly[]>(
  "assemblies",
  DEFAULT_ASSEMBLIES
);

export const createAssemblyCommand = atom(null, (get, set, name: string) => {
  set(assembliesState, (assemblies) => [
    ...assemblies,
    {
      id: uuid4(),
      name,
      instructions: "",
    },
  ]);
});
