import { useAtomValue } from "jotai";
import { FC } from "react";
import { transpiledState } from "../../state";
import { outputCss } from "../Output/styles";

export const TranspiledOutput: FC = () => {
  const transpiled = useAtomValue(transpiledState);

  return (
    <div data-code data-color={400} css={outputCss(transpiled.status)}>
      {transpiled.status === "success" ? transpiled.output : transpiled.error}
    </div>
  );
};
