import { MosaicNode } from "react-mosaic-component";

export const VIEWS = [
  "assemblies",
  "viewport",
  "editor",
  "transpiled",
  "evaluated",
  "evaluator-logs",
  "documentation",
  "colors",
] as const;

type ViewIds = typeof VIEWS;

export type MosaicWindowKey = ViewIds[number];

export type LayoutNode = MosaicNode<MosaicWindowKey>;
