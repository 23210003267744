import { atom } from "jotai";
import { activeAssemblyState } from "./activeAssemblyState";

export const activeAssemblyNameState = atom((get) => {
  const activeAssembly = get(activeAssemblyState);

  if (activeAssembly) {
    return activeAssembly.name;
  }

  return "";
});
