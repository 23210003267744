import { css } from "@emotion/react";
import colors, { ColorFamily } from "./colors";
import { transition } from "./transition";
import { map } from "lodash";

export const shadow = css``;

export const BASE_COLOR = colors.zinc;
export const ACCENT_COLOR = colors.lime;
export const ERROR_COLOR = colors.rose;

const LINE_HEIGHT = "160%;";

const createTextColorRules = (family: ColorFamily, name = "") =>
  map(
    family,
    (color, shade) => css`
      [data${name}-color="${shade}"] {
        color: ${color};
      }
    `
  );

export const globalCss = css`
  * {
    scrollbar-width: thin;
    scrollbar-color: ${BASE_COLOR[700]} transparent;
  }

  body {
    background-color: ${BASE_COLOR[900]};
    color: ${BASE_COLOR[50]};

    font-size: 16px;

    /* Set the color of the scrollbar track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* Set the color of the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
      background-color: ${BASE_COLOR[500]};
    }

    /* Set the color of the scrollbar thumb on hover */
    &::-webkit-scrollbar-thumb:hover {
      background-color: ${BASE_COLOR[600]};
    }

    scrollbar-color: ${BASE_COLOR[500]} transparent;
  }

  ::selection {
    background-color: ${BASE_COLOR[600]};
    color: ${BASE_COLOR[50]};
  }

  p {
    margin: 20px 0;
    line-height: ${LINE_HEIGHT};

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  pre {
    padding: 0;
    margin: 0;
  }

  strong {
    color: ${BASE_COLOR[50]};
  }

  [data-strong="true"] {
    font-weight: 600;
  }

  code {
    color: ${ACCENT_COLOR[300]};
    font-size: 14px;
  }

  [data-muted="true"] {
    opacity: 0.66;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > legend {
      font-weight: bold;
      color: ${BASE_COLOR[800]};
      padding: 0;
      margin: 0;
    }

    & > [data-form-row="true"] {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    & [data-form-actions="true"] {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 5px;
    }
  }

  &[data-is-panel] {
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;

    ${shadow}
  }

  input,
  textarea,
  select {
    font-family: inherit;
    line-height: ${LINE_HEIGHT};
    background-color: inherit;
    padding: 9px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid ${BASE_COLOR[200]};
    color: ${BASE_COLOR[50]};

    transition: ${transition("box-shadow", "background-color")};

    &:focus {
      outline: none;
      ${shadow}
    }

    &[readonly] {
      background-color: ${BASE_COLOR[800]};
      color: ${BASE_COLOR[200]};
    }
  }

  textarea {
    min-height: 300px;
  }

  input[type="checkbox"] {
    position: relative;
    appearance: none;
    border: 1px solid ${BASE_COLOR[50]};
    width: 14px;
    height: 14px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    transition: background-color 200ms ease-in-out,
      border-color 200ms ease-in-out;
    cursor: pointer;
  }
  input[type="checkbox"]:hover {
    border-color: ${BASE_COLOR[300]};
  }

  input[type="checkbox"]:checked {
    position: relative;
    appearance: none;
    background-color: ${BASE_COLOR[50]};
  }
  input[type="checkbox"]:checked:hover {
    background-color: ${BASE_COLOR[300]};
  }

  label {
    cursor: var(--cursor);
    color: var(--ui-color);
    transition: ${transition("color", "background-color")};
    font-size: 80%;
  }
  label:hover {
    color: ${BASE_COLOR[300]};
    cursor: pointer;
  }

  a {
    color: ${ACCENT_COLOR[400]};
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;

    transition: ${transition("color")};

    &:hover {
      color: ${ACCENT_COLOR[200]};
    }

    > strong {
      color: inherit;
    }

    &[data-active="true"] {
      color: ${ACCENT_COLOR[200]};
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    min-width: 36px;
    min-height: 36px;
    box-sizing: border-box;

    padding: 5px 10px;
    border: 1px solid ${BASE_COLOR[200]};
    border-radius: 5px;
    color: ${BASE_COLOR[200]};
    background-color: transparent;
    font-weight: 900;
    font-size: 70%;
    text-transform: uppercase;
    cursor: pointer;

    transition: ${transition("color", "background-color")};

    &:hover {
      color: ${BASE_COLOR[100]};
      background-color: ${BASE_COLOR[800]};
    }

    &:focus {
      outline: none;
      color: ${BASE_COLOR[100]};
      background-color: ${BASE_COLOR[800]};
    }

    svg {
      fill: currentColor;
    }

    &[data-borderless="true"] {
      border-color: transparent;
    }

    &[data-active="true"] {
      color: ${ACCENT_COLOR[500]};
    }

    &[data-danger="true"] {
      &:hover,
      &:focus {
        color: ${ERROR_COLOR[500]};
      }
    }

    &[data-size="small"] {
      padding: 5px;
    }
  }

  button > svg,
  a > svg {
    min-width: 14px;
    min-height: 14px;
  }

  [data-button-group] {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    text-transform: uppercase;
    color: ${BASE_COLOR[300]};
  }

  [data-code="true"] {
    font-family: monospace;
    font-size: 14px;
    tab-size: 2;
    line-height: 150%;
  }

  ${createTextColorRules(BASE_COLOR)}
  ${createTextColorRules(ACCENT_COLOR, "-accent")}
`;
