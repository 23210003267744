import { css } from "@emotion/react";
import { Component, ErrorInfo, PropsWithChildren } from "react";
import { ERROR_COLOR } from "../../styles/globals";

type Props = PropsWithChildren;

type State = {
  hasError: boolean;
  error?: Error;
};

const errorCss = css`
  overflow: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px;
  font-size: 14px;
  color: ${ERROR_COLOR[500]};
  display: flex;
  flex-direction: column;
  gap: 20px;

  pre {
    line-height: 150%;
  }
`;

export class ErrorBoundary extends Component<Props, State> {
  error = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      hasError: true,
      error,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div css={errorCss}>
          <strong>View failed to render due to error:</strong>
          <pre>{this.state.error?.message}</pre>
          <pre>{this.state.error?.stack}</pre>
        </div>
      );
    }

    return this.props.children;
  }
}
