import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import {
  SubtractionElementProps,
  SubtractionProps,
  SceneElement,
  SceneElementChildren,
} from "../types";
import { processChildren } from "./helpers/processChildren";

const processProps = (
  props: SubtractionProps = {}
): SubtractionElementProps => ({
  ...props,
});

export const subtraction = (
  props: SubtractionProps,
  ...children: SceneElementChildren
): SceneElement => ({
  type: "subtraction",
  props: processProps(props),
  children: processChildren(children),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
make a subtraction from a CSG (Constructive Solid Geometry) group's base shape.
`;

const example1 = makeExample(`
(geometry { position: [1 0 1] }
  (base {}
    (box { W: 1, H: 1, L: 1 }))
  (subtraction {}
    (box { W: 0.5, H: 2, L: 0.5 })))
`);

export const SubtractionDocumentation: DocNode = {
  name: "subtraction",
  description,
  arguments: ["props", "children"],
  props: findPropsDeclaration("SubtractionProps"),
  tags: ["geometry", "CSG"],
  examples: [example1],
};
