import { css } from "@emotion/react";
import { FC } from "react";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Canvas, RootState } from "@react-three/fiber";
import { Perf } from "r3f-perf";
import { BASE_COLOR } from "../../styles/globals";
import * as THREE from "three";
import { SceneRenderer } from "./SceneRenderer";
import { SceneError } from "./SceneError";
import { Credits } from "./Credits";
import { useAtomValue } from "jotai";
import { sceneKeyState } from "./state";
import { SceneBusy } from "./SceneBusy";
import { cameraConfigurationState } from "../../state/unitsState";

const containerCss = css`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BASE_COLOR[900]};
`;

const BACKGROUND = new THREE.Color(BASE_COLOR[900]);

const onCanvasCreated = ({ gl }: RootState) => {
  gl.setClearColor(BACKGROUND, 0);
};

const isPerfEnabled = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has("perf");
};

export const Viewport: FC = () => {
  // EXPLANATION: cause the SceneRenderer to remount when
  // the assembly changes to ensure no THREE.js state is stale.
  const sceneKey = useAtomValue(sceneKeyState);

  const camera = useAtomValue(cameraConfigurationState);

  return (
    <div css={containerCss}>
      <Canvas onCreated={onCanvasCreated}>
        {isPerfEnabled() && <Perf position="top-left" />}

        <PerspectiveCamera makeDefault position={camera.defaultPosition} />

        <OrbitControls
          minDistance={camera.minDistance}
          maxDistance={camera.maxDistance}
          enableDamping
          enablePan
          enableRotate
          enableZoom
        />

        <SceneRenderer key={sceneKey} />
      </Canvas>

      <Credits />
      <SceneBusy />
      <SceneError />
    </div>
  );
};
