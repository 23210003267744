import { mesh } from "./mesh";
import { group } from "./group";
import { position } from "./position";
import { rotation } from "./rotation";
import { scale } from "./scale";
import { ignore } from "./ignore";
import { geometry } from "./geometry";
import { base } from "./base";
import { addition } from "./addition";
import { subtraction } from "./subtraction";
import { box } from "./box";
import { sphere } from "./sphere";
import { cylinder } from "./cylinder";
import { cone } from "./cone";
import { lathe } from "./lathe";
import { extrude } from "./extrude";
import { capsule } from "./capsule";
import { torus } from "./torus";
import * as math from "./math";

const PREAMBLE = {
  ...Math,
  ...math,
  // Scene primitives
  mesh,
  group,
  // Helpers
  position,
  rotation,
  scale,
  ignore,
  // CSG primitives
  geometry,
  base,
  addition,
  subtraction,
  // Geometry primitives
  box,
  sphere,
  cylinder,
  cone,
  lathe,
  extrude,
  capsule,
  torus,
};

export default PREAMBLE;
