import { BoxElementProps, BoxProps, SceneElement } from "../types";
import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";

const processProps = (props: BoxProps = {}): BoxElementProps => ({
  ...props,
  args: [
    props.W ?? props.args?.[0],
    props.H ?? props.args?.[1],
    props.L ?? props.args?.[2],
  ],
});

export const box = (props: BoxProps): SceneElement => ({
  type: "box",
  props: processProps(props),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a box geometry.`;

const example1 = makeExample(`
(box { L: 2, W: 2, H: 1, color: 'indigo500 })
`);

export const BoxDocumentation: DocNode = {
  name: "box",
  description,
  arguments: [`props`],
  props: findPropsDeclaration("BoxProps"),
  tags: ["geometry", "primitive"],
  examples: [example1],
};
