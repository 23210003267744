import { isNumber } from "lodash";
import { ObjectProps, Rotations } from "../../types";

type ProcessedProps<P extends ObjectProps> = P & {
  rotation?: [number, number, number];
};

const parseRadians = (raw: string | undefined): number => {
  if (!raw) {
    return 0;
  }
  return raw.startsWith("r") ? parseFloat(raw.slice(1)) : parseFloat(raw);
};

const degreesToRadians = (degrees: number): number => degrees * (Math.PI / 180);

const processElementRotation = ([a, b, c]: Rotations): [
  number,
  number,
  number
] => {
  const pa = isNumber(a) ? degreesToRadians(a) : parseRadians(a);
  const pb = isNumber(b) ? degreesToRadians(b) : parseRadians(b);
  const pc = isNumber(c) ? degreesToRadians(c) : parseRadians(c);
  return [pa, pb, pc];
};

export const processElementProps = <P extends ObjectProps>({
  rotation,
  ...props
}: P): ProcessedProps<P> => {
  return {
    ...props,
    rotation: rotation ? processElementRotation(rotation) : rotation,
  } as ProcessedProps<P>;
};
