import { useAtomValue } from "jotai";
import { FC } from "react";
import { evaluatedState } from "../../state";
import { BASE_COLOR, ERROR_COLOR } from "../../styles/globals";
import { Box, Edges, Grid } from "@react-three/drei";
import { Axes } from "./Axes";
import * as THREE from "three";
import { SceneElementRenderer } from "./SceneElementRenderer";
import {
  EffectComposer,
  Outline,
  Selection,
} from "@react-three/postprocessing";
import { gridConfigurationState } from "../../state/unitsState";

const CELL_COLOR = new THREE.Color(BASE_COLOR[500]);
const SECTION_COLOR = new THREE.Color(BASE_COLOR[400]);

const SceneErrorRenderer: FC = () => (
  <Box>
    <meshBasicMaterial color={ERROR_COLOR[400]} />
    <Edges />
  </Box>
);

export const SceneRenderer: FC = () => {
  const evaluated = useAtomValue(evaluatedState);

  const content =
    evaluated.status === "success" ? (
      <SceneElementRenderer isRoot element={evaluated.output} />
    ) : (
      <SceneErrorRenderer />
    );

  const grid = useAtomValue(gridConfigurationState);

  return (
    <Selection>
      <EffectComposer multisampling={2} autoClear={false}>
        <Outline
          blur
          visibleEdgeColor={0xffffff}
          hiddenEdgeColor={0xffffff}
          edgeStrength={100}
          width={1000}
        />
      </EffectComposer>

      <group name="scene">
        <Axes size={grid.axisSize} />
        <Grid
          side={2}
          infiniteGrid
          sectionColor={SECTION_COLOR}
          sectionSize={grid.sectionSize}
          cellColor={CELL_COLOR}
          cellSize={grid.cellSize}
          fadeDistance={grid.fadeDistance}
          fadeStrength={5}
        />

        {content}

        <pointLight position={[12.5, 50, 25]} intensity={10000} />
        <pointLight position={[12.5, -50, 25]} intensity={5000} />
        <ambientLight />
      </group>
    </Selection>
  );
};
