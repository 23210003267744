import { atom } from "jotai";
import { assembliesState } from "./assembliesState";
import { activeAssemblyIdState } from "./activeAssemblyIdState";

export const deleteAssemblyCommand = atom(
  null,
  (get, set, idToDelete: string) => {
    const activeAssemblyId = get(activeAssemblyIdState);

    if (activeAssemblyId === idToDelete) {
      const assemblyToActivate = get(assembliesState).find(
        (assembly) => assembly.id !== activeAssemblyId
      );

      if (assemblyToActivate) {
        set(activeAssemblyIdState, assemblyToActivate.id);
      }
    }

    set(assembliesState, (assemblies) =>
      assemblies.filter((assembly) => assembly.id !== idToDelete)
    );
  }
);
