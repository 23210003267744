import { FC, PropsWithChildren } from "react";
import { FloatingBox } from "./FloatingBox";
import { css } from "@emotion/react";
import { BASE_COLOR } from "../../styles/globals";

interface Props {
  onCloseMenu: () => void;
}

const menuContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 200px;

  button {
    justify-content: start;
    border: none;
  }

  label {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 5px;
    text-transform: uppercase;
  }

  button,
  label {
    font-size: 70%;
    font-weight: 600;
    padding: 0 10px;
    min-height: 30px;
    border-radius: 5px;
    text-transform: unset;

    &:hover {
      background-color: ${BASE_COLOR[700]};
    }
  }

  hr {
    border: none;
    width: calc(100% + 10px);
    height: 1px;
    margin: 0 -5px;
    background-color: ${BASE_COLOR[400]};
  }
`;

export const FloatingMenu: FC<PropsWithChildren<Props>> = ({
  onCloseMenu,
  children,
}) => {
  return (
    <FloatingBox onClickOutside={onCloseMenu}>
      <div css={menuContainerCss}>{children}</div>
    </FloatingBox>
  );
};
