import { DocNode } from "../../docs/types";
import { makeExample } from "../../docs/utils";

const description = `
mixed numbers with a fractional part can be used to specify geometry dimensions.
`;

const example1 = makeExample(`
;; A mixed number can be encoded using a traditional infix notation for the fractional part.
(f 33 '_1/3)
`);

const example2 = makeExample(`
;; Or using the prefix / operator
(f 33 (/ 1 3))
`);

export const FractionsDocumentation: DocNode = {
  name: "f (fractions)",
  description,
  arguments: ["whole-part", "fractional-part"],
  tags: ["math", "units"],
  examples: [example1, example2],
};
