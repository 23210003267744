import { ConfigurationForUnit, Unit } from "./types";

export const UNITS: Record<Unit, ConfigurationForUnit> = {
  mm: {
    camera: {
      minDistance: 20,
      maxDistance: 250,
      defaultPosition: [150, 70, 150],
    },
    grid: {
      axisSize: 100,
      sectionSize: 10,
      cellSize: 1,
      fadeDistance: 500,
    },
  },
  inch: {
    camera: {
      minDistance: 5,
      maxDistance: 100,
      defaultPosition: [15, 7, 15],
    },
    grid: {
      axisSize: 25,
      sectionSize: 1,
      cellSize: 0.125,
      fadeDistance: 100,
    },
  },
};
