import { LayoutNode, MosaicWindowKey } from "./types";
import { useCallback } from "react";
import { persistentAtom } from "../../utils/persistentAtom";
import { useAtomCallback } from "jotai/utils";
import { atom } from "jotai";
import {
  Corner,
  MosaicDirection,
  MosaicNode,
  MosaicParent,
  getLeaves,
  getNodeAtPath,
  getOtherDirection,
  getPathToCorner,
  updateTree,
} from "react-mosaic-component";
import { dropRight } from "lodash";

const DEFAULT_LAYOUT: LayoutNode = {
  direction: "row",
  first: {
    first: { first: "assemblies", second: "evaluated", direction: "column" },
    second: "viewport",
    direction: "row",
    splitPercentage: 30,
  },
  second: "editor",
  splitPercentage: 60,
};

export const layoutState = persistentAtom<LayoutNode>(
  "layout-state",
  DEFAULT_LAYOUT
);

export const useResetLayout = () =>
  useAtomCallback(
    useCallback((_get, set) => {
      set(layoutState, DEFAULT_LAYOUT);
    }, [])
  );

export const isDraggingState = atom(false);

export const openViewCommand = atom(
  null,
  (get, set, viewId: MosaicWindowKey) => {
    const layout = get(layoutState);
    const views = getLeaves(layout);

    if (!views.includes(viewId)) {
      if (layout) {
        const path = getPathToCorner(layout, Corner.TOP_RIGHT);
        const parent = getNodeAtPath(
          layout,
          dropRight(path)
        ) as MosaicParent<MosaicWindowKey>;
        const destination = getNodeAtPath(
          layout,
          path
        ) as MosaicNode<MosaicWindowKey>;
        const direction: MosaicDirection = parent
          ? getOtherDirection(parent.direction)
          : "row";

        let first: MosaicNode<MosaicWindowKey>;
        let second: MosaicNode<MosaicWindowKey>;
        if (direction === "row") {
          first = destination;
          second = viewId;
        } else {
          first = viewId;
          second = destination;
        }

        const updated: LayoutNode = updateTree(layout, [
          {
            path,
            spec: {
              $set: {
                direction,
                first,
                second,
              },
            },
          },
        ]);

        set(layoutState, updated);
      } else {
        set(layoutState, viewId);
      }
    }
  }
);
