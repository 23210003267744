import ExtrudeSample from "./extrude-sample.json";
import LatheSample from "./lathe-sample.json";
import Spinner from "./spinner.json";
import MinimalistWalletPanel from "./minimalist-wallet-panel.json";
import UseAssemblyExample from "./use-assembly-example.json";
import PocketDriver from "./pocket-driver.json";

export const EXAMPLES = [
  ExtrudeSample,
  LatheSample,
  UseAssemblyExample,
  Spinner,
  PocketDriver,
  MinimalistWalletPanel,
];
