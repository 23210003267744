export const indexOf = function (xs: unknown[], item: unknown) {
  if (xs.indexOf) return xs.indexOf(item);
  else
    for (var i = 0; i < xs.length; i++) {
      if (xs[i] === item) return i;
    }
  return -1;
};

export const Object_keys = function (obj: any) {
  if (Object.keys) return Object.keys(obj);
  else {
    var res = [];
    for (var key in obj) res.push(key);
    return res;
  }
};

export const forEach = function <X>(
  xs: X[],
  fn: (x: X, index: number, xs: X[]) => void
) {
  if (xs.forEach) return xs.forEach(fn);
  else
    for (var i = 0; i < xs.length; i++) {
      fn(xs[i], i, xs);
    }
};

export const defineProp = (function () {
  try {
    Object.defineProperty({}, "_", {});
    return function (obj: any, name: string, value: unknown) {
      Object.defineProperty(obj, name, {
        writable: true,
        enumerable: false,
        configurable: true,
        value: value,
      });
    };
  } catch (e) {
    return function (obj: any, name: string, value: unknown) {
      obj[name] = value;
    };
  }
})();
