import { atom } from "jotai";
import { Assembly } from "../types";
import { assembliesState } from "./assembliesState";
import { activeAssemblyIdState } from "./activeAssemblyIdState";

export const activeAssemblyState = atom(
  (get) => {
    const assemblies = get(assembliesState);
    const activeAssemblyId = get(activeAssemblyIdState);

    return (
      assemblies.find((assembly) => assembly.id === activeAssemblyId) ?? null
    );
  },
  (_get, set, update: Assembly) => {
    set(assembliesState, (assemblies) =>
      assemblies.map((assembly) =>
        assembly.id === update.id ? update : assembly
      )
    );
  }
);
