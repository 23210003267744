import { css } from "@emotion/react";
import { FC } from "react";
import { useAtomValue } from "jotai";
import { isUpdatingViewportState } from "../../state/isUpdatingViewportState";

const containerCss = css`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  backdrop-filter: blur(10px) grayscale(75%);
`;

export const SceneBusy: FC = () => {
  const isUpdatingViewport = useAtomValue(isUpdatingViewportState);

  if (!isUpdatingViewport) {
    return null;
  }

  return (
    <div css={containerCss}>
      <strong>Updating viewport</strong>
    </div>
  );
};
