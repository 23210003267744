import { DocNode } from "../../docs/types";
import { makeExample } from "../../docs/utils";

const description = `
angles can be in degrees or radians. angles are in degrees unless specified as being in radians.

an angle can be explicitly specified as radians by prefixing with a <code>r</code>.
`;

const example1 = makeExample(`
;; This will be in degrees
45
`);

const example2 = makeExample(`
;; This will be in radians
'r2
`);

export const AnglesDocumentation: DocNode = {
  name: "angles",
  description,
  arguments: [],
  tags: ["math", "units", "coordinateSystem"],
  examples: [example1, example2],
};
