import { css } from "@emotion/react";
import { FC } from "react";
import { ACCENT_COLOR, BASE_COLOR } from "../../styles/globals";
import useCopy from "@react-hook/copy";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import { transition } from "../../styles/transition";

interface Props {
  children: string;
}

const containerCss = css`
  position: relative;

  & button {
    transition: ${transition("opacity")};
    opacity: 0;
  }

  &:hover button {
    opacity: 1;
  }
`;

const copyButtonContainerCss = css`
  position: absolute;
  top: 5px;
  right: 5px;

  & > button {
    background-color: ${BASE_COLOR[800]};
  }
`;

const exampleCss = css`
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
  color: ${ACCENT_COLOR[300]};
  background-color: ${BASE_COLOR[800]};
  overflow: auto;
  max-width: 100%;
`;

export const ContentExample: FC<Props> = ({ children }) => {
  const { copy } = useCopy(children);

  return (
    <div css={containerCss}>
      <pre data-code data-accent-color={300} css={exampleCss}>
        {children}
      </pre>

      <div css={copyButtonContainerCss}>
        <button type="button" data-size="small" onClick={copy}>
          <Icon path={mdiContentCopy} size={0.5} />
        </button>
      </div>
    </div>
  );
};
