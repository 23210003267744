import { css } from "@emotion/react";
import { FC, PropsWithChildren, useRef } from "react";
import { BASE_COLOR } from "../../styles/globals";
import useClickOutside from "use-click-outside";

type Props = PropsWithChildren<{
  onClickOutside: () => void;
  maxHeight?: number | string;
}>;

const containerCss = css`
  position: absolute;
  z-index: 1000;
  top: calc(100% + 3px);
  right: 0;
  background-color: ${BASE_COLOR[800]};
  border: 1px solid ${BASE_COLOR[400]};
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.66);
  overflow: auto;
`;

const containerStyle = (maxHeight?: number | string) => ({
  maxHeight,
});

export const FloatingBox: FC<Props> = ({
  children,
  onClickOutside,
  maxHeight,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClickOutside, "mousedown");

  return (
    <div ref={ref} css={containerCss} style={containerStyle(maxHeight)}>
      {children}
    </div>
  );
};
