import { css } from "@emotion/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { editorState } from "../../state";
import { Editor, Monaco } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import { ACCENT_COLOR, BASE_COLOR } from "../../styles/globals";
import { activeAssemblyIdState } from "../../state/activeAssemblyIdState";
import { isUpdatingViewportState } from "../../state/isUpdatingViewportState";
import { useAtomCallback } from "jotai/utils";

const containerCss = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
`;

const editorContainerCss = css`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: visible;
`;

const MONACO_OPTIONS: editor.IStandaloneEditorConstructionOptions = {
  minimap: { enabled: false },
  scrollBeyondLastLine: false,
  fontSize: 14,
  // @ts-expect-error for some reason the type doesn't reflect the correct key
  "bracketPairColorization.enabled": false,
  matchBrackets: "near",
};

const MONACO_THEME: editor.IStandaloneThemeData = {
  base: "vs-dark",
  inherit: true,
  rules: [
    {
      token: "comment",
      foreground: BASE_COLOR[400],
    },
    { token: "number", foreground: ACCENT_COLOR[400] },
    { token: "string", foreground: ACCENT_COLOR[200] },
    { token: "regexp", foreground: BASE_COLOR[300] },
    { token: "key", foreground: BASE_COLOR[700] },
    { token: "variable", foreground: "ff8000" },
    { token: "delimiter", foreground: BASE_COLOR[700] },
  ],
  colors: {
    "editor.background": BASE_COLOR[900],
    "editor.foreground": BASE_COLOR[50],
  },
};

export const MonacoEditor: FC = () => {
  const [code, setCode] = useAtom(editorState);
  const setUpdatingViewport = useSetAtom(isUpdatingViewportState);
  const [local, setLocal] = useState(code);

  const activeAssemblyId = useAtomValue(activeAssemblyIdState);

  const getCode = useAtomCallback(useCallback((get) => get(editorState), []));

  useEffect(() => {
    setLocal(getCode());
  }, [getCode, activeAssemblyId]);

  const updateCode = useDebouncedCallback((update: string) => {
    setCode(update);
    setUpdatingViewport(false);
  }, 500);

  const onChange = useCallback(
    (update: string | undefined) => {
      if (update !== undefined) {
        setLocal(update);
        setUpdatingViewport(true);
        updateCode(update);
      }
    },
    [setUpdatingViewport, updateCode]
  );

  const onMounted = useCallback(
    (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => {
      monaco.editor.defineTheme("dark", MONACO_THEME);
      monaco.editor.setTheme("dark");

      editor.getModel()?.updateOptions({ tabSize: 2 });
    },
    []
  );

  return (
    <div css={containerCss}>
      <div css={editorContainerCss}>
        <Editor
          path={activeAssemblyId}
          height="100%"
          defaultLanguage="scheme"
          options={MONACO_OPTIONS}
          value={local}
          onChange={onChange}
          onMount={onMounted}
        />
      </div>
    </div>
  );
};
