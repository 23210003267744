import { FC, useCallback, useState } from "react";
import { saveAs } from "file-saver";
import { Assembly } from "../../types";
import Icon from "@mdi/react";
import {
  mdiDotsHorizontal,
  mdiFolderOpenOutline,
  mdiFolderOutline,
} from "@mdi/js";
import { css } from "@emotion/react";
import { useAtomValue, useSetAtom } from "jotai";
import {
  activateAssemblyCommand,
  isActiveAssemblyState,
} from "../../state/activeAssemblyIdState";
import { deleteAssemblyCommand } from "../../state/deleteAssemblyCommand";
import { FloatingMenu } from "../controls/FloatingMenu";
import { renameAssemblyCommand } from "../../state/renameAssemblyCommand";
import { duplicateAssemblyCommand } from "../../state/duplicateAssemblyCommand";
import { getCurrentDateTime } from "../../utils/getCurrentDateTime";

interface Props {
  assembly: Assembly;
}

const layoutCss = css`
  position: relative;
  display: flex;
  align-items: center;
  gap: 3px;
`;

const buttonCss = css`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: start;
  width: 100%;
  text-transform: none;
  text-align: start;
`;

interface MenuProps {
  closeMenu: () => void;
  assembly: Assembly;
}

const Menu: FC<MenuProps> = ({ closeMenu, assembly }) => {
  const renameAssembly = useSetAtom(renameAssemblyCommand);
  const onRename = useCallback(() => {
    const name = window.prompt("Enter the new assembly name", assembly.name);

    if (name) {
      renameAssembly(assembly.id, name);
    }

    closeMenu();
  }, [assembly.id, assembly.name, closeMenu, renameAssembly]);

  const duplicateAssembly = useSetAtom(duplicateAssemblyCommand);
  const onDuplicate = useCallback(() => {
    const name = window.prompt("Enter the new assembly name");

    if (name) {
      duplicateAssembly(assembly.id, name);
    }

    closeMenu();
  }, [assembly.id, closeMenu, duplicateAssembly]);

  const onExport = useCallback(() => {
    const serialized = JSON.stringify(assembly, null, 2);
    const blob = new Blob([serialized], {
      type: "text/plain;charset=utf-8",
    });

    const filename = `${assembly.name}-${getCurrentDateTime()}.json`;

    saveAs(blob, filename);
  }, [assembly]);

  const deleteAssembly = useSetAtom(deleteAssemblyCommand);
  const onDelete = useCallback(() => {
    if (window.confirm(`Are you sure you want to delete this assembly?`)) {
      deleteAssembly(assembly.id);
    }

    closeMenu();
  }, [closeMenu, deleteAssembly, assembly.id]);

  return (
    <FloatingMenu onCloseMenu={closeMenu}>
      <button onClick={onRename}>Rename Assembly</button>
      <button onClick={onDuplicate}>Duplicate Assembly</button>

      <hr />

      <button onClick={onExport}>Export Assembly</button>

      <hr />

      <button data-danger onClick={onDelete}>
        Delete Assembly
      </button>
    </FloatingMenu>
  );
};

export const AssemblyListItem: FC<Props> = ({ assembly }) => {
  const isActive = useAtomValue(isActiveAssemblyState(assembly.id));
  const activateAssembly = useSetAtom(activateAssemblyCommand);

  const onClick = useCallback(() => {
    activateAssembly(assembly.id);
  }, [activateAssembly, assembly.id]);

  const iconPath = isActive ? mdiFolderOpenOutline : mdiFolderOutline;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((b) => !b);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <div css={layoutCss}>
      <button
        type="button"
        data-borderless
        data-active={isActive}
        css={buttonCss}
        onClick={onClick}
      >
        <Icon path={iconPath} size={0.7} />
        {assembly.name}
      </button>

      <button data-borderless onClick={toggleMenu}>
        <Icon path={mdiDotsHorizontal} size={0.7} />
      </button>

      {isMenuOpen && <Menu assembly={assembly} closeMenu={closeMenu} />}
    </div>
  );
};
