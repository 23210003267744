import { atom } from "jotai";
import { activeAssemblyState } from "./activeAssemblyState";

export const editorState = atom(
  (get) => {
    const activeAssembly = get(activeAssemblyState);

    if (activeAssembly) {
      return activeAssembly.instructions;
    }

    return "";
  },
  (get, set, instructions: string) => {
    const activeAssembly = get(activeAssemblyState);

    if (activeAssembly) {
      set(activeAssemblyState, { ...activeAssembly, instructions });
    }
  }
);
