import { atom } from "jotai";
import { persistentAtom } from "../utils/persistentAtom";
import { DEFAULT_ASSEMBLY } from "./constants";
import { atomFamily } from "jotai/utils";

export const activeAssemblyIdState = persistentAtom<string>(
  "activeAssemblyId",
  DEFAULT_ASSEMBLY.id
);

export const activateAssemblyCommand = atom(null, (_get, set, id: string) => {
  set(activeAssemblyIdState, id);
});

export const isActiveAssemblyState = atomFamily((id: string) =>
  atom((get) => {
    const activeAssemblyId = get(activeAssemblyIdState);
    return activeAssemblyId === id;
  })
);
