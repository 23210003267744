import { isNumber, isString } from "lodash";
import * as THREE from "three";

export const v2 = (x: number, y: number) => new THREE.Vector2(x, y);

export const v3 = (x: number, y: number, z: number) =>
  new THREE.Vector3(x, y, z);

// Fractions are encoded as "_{numerator}/{denominator}"
const parseFraction = (raw: string | number) => {
  if (isNumber(raw)) {
    return raw;
  }

  if (!isString(raw)) {
    throw new Error(`${raw} is an invalid fraction format.`);
  }

  if (!raw.startsWith("_")) {
    throw new Error(`${raw} is an invalid fraction format.`);
  }

  const parts = raw.slice(1).split("/");
  return parseInt(parts[0], 10) / parseInt(parts[1], 10);
};

export const f = (whole: number, fraction: string) =>
  whole + parseFraction(fraction);
