import { DocNode } from "../../docs/types";
import { makeExample } from "../../docs/utils";

const description = `
colors can be any valid CSS color representation.

a cohesive palette of colors is included and can be seen in the Colors view.
`;

const example1 = makeExample(`
;; Named CSS colors can be a quoted symbol
'red

;; or a string
"red"
`);

const example2 = makeExample(`
;; Color palette colors can be a quoted symbol
'teal500

;; or a string
"teal500"
`);

const example3 = makeExample(`
;; Hexadecimal colors must be enclosed as a string
"#ff8000"
`);

export const ColorsDocumentation: DocNode = {
  name: "colors",
  description,
  arguments: [],
  tags: ["geometry"],
  examples: [example1, example2, example3],
};
