import { Fragment, FC, useCallback, useState, memo } from "react";
import {
  Box,
  Capsule,
  Cone,
  Cylinder,
  Extrude,
  Html,
  Lathe,
  Sphere,
  Torus,
} from "@react-three/drei";
import { Select } from "@react-three/postprocessing";
import { ThreeEvent } from "@react-three/fiber";
import JSON5 from "json5";
import { isNil, omit } from "lodash";
import { ERROR_COLOR } from "../../styles/globals";
import { SceneElement } from "../../types";
import { getColor } from "../../styles/getColor";
import { SceneElementTooltip } from "./SceneElementTooltip";
import { Measurements } from "./Measurements";
import {
  Addition,
  Base,
  Difference,
  Geometry,
  Intersection,
  Subtraction,
} from "@react-three/csg";
import { WithinGeometry, useIsWithinGeometry } from "./Context";
import { processElementProps } from "./processElementProps";
import { useExportableRef } from "../../exporting";
import { useAtomValue } from "jotai";
import { shadingModeState } from "../../state/renderingOptionsState";

interface Props {
  element: SceneElement | undefined | null;
  isRoot?: true;
}

const Material: FC<{ color: string | undefined; side?: 0 | 1 | 2 }> = ({
  color,
  side = 0,
}) => {
  const shadingMode = useAtomValue(shadingModeState);

  return (
    <meshPhysicalMaterial
      color={getColor(color)}
      side={side}
      metalness={0.1}
      flatShading={shadingMode === "flat"}
    />
  );
};

const SceneElementDisplay: FC<Props> = ({ element, isRoot }) => {
  const isWithinGeometry = useIsWithinGeometry();

  const setSceneRoot = useExportableRef(isRoot);

  if (isNil(element)) {
    return null;
  }

  if (element.type === "ignore") {
    return null;
  }

  if (
    element.type === "group" ||
    element.type === "position" ||
    element.type === "rotation" ||
    element.type === "scale"
  ) {
    return (
      <group {...processElementProps(element.props)} ref={setSceneRoot}>
        {element.children?.flatMap((child, index) => (
          <SceneElementRenderer key={index} element={child} />
        ))}
      </group>
    );
  }

  if (element.type === "mesh") {
    return (
      <mesh {...processElementProps(element.props)}>
        {element.children?.flatMap((child, index) => (
          <SceneElementRenderer key={index} element={child} />
        ))}
      </mesh>
    );
  }

  if (element.type === "geometry") {
    const { position, rotation, ...props } = processElementProps(element.props);
    return (
      <mesh position={position} rotation={rotation}>
        <Geometry {...props}>
          <WithinGeometry>
            {element.children?.flatMap((child, index) => (
              <SceneElementRenderer key={index} element={child} />
            ))}
          </WithinGeometry>
        </Geometry>

        <Material color={element.props.color} side={2} />
      </mesh>
    );
  }

  if (element.type === "base") {
    return (
      <Base {...processElementProps(element.props)}>
        {element.children?.map((child, index) => (
          <SceneElementRenderer key={index} element={child} />
        ))}
      </Base>
    );
  }

  if (element.type === "addition") {
    return (
      <Addition {...processElementProps(element.props)}>
        {element.children?.map((child, index) => (
          <SceneElementRenderer key={index} element={child} />
        ))}
      </Addition>
    );
  }

  if (element.type === "subtraction") {
    return (
      <Subtraction {...processElementProps(element.props)}>
        {element.children?.map((child, index) => (
          <SceneElementRenderer key={index} element={child} />
        ))}
      </Subtraction>
    );
  }

  if (element.type === "intersection") {
    return (
      <Intersection {...processElementProps(element.props)}>
        {element.children?.map((child, index) => (
          <SceneElementRenderer key={index} element={child} />
        ))}
      </Intersection>
    );
  }

  if (element.type === "difference") {
    return (
      <Difference {...processElementProps(element.props)}>
        {element.children?.map((child, index) => (
          <SceneElementRenderer key={index} element={child} />
        ))}
      </Difference>
    );
  }

  if (element.type === "box") {
    if (isWithinGeometry) {
      return <boxGeometry {...processElementProps(element.props)} />;
    }

    return (
      <Fragment>
        <Box {...omit(processElementProps(element.props), "position")}>
          <Material color={element.props.color} />
        </Box>
        <Measurements
          measure={element.props.measure}
          w={element.props.args[0]}
          l={element.props.args[2]}
          h={element.props.args[1]}
        />
      </Fragment>
    );
  }

  if (element.type === "sphere") {
    if (isWithinGeometry) {
      return <sphereGeometry {...processElementProps(element.props)} />;
    }

    const diameter = (element.props.args[0] ?? 1) * 2;
    return (
      <Fragment>
        <Sphere {...omit(processElementProps(element.props), "position")}>
          <Material color={element.props.color} />
        </Sphere>
        <Measurements
          measure={element.props.measure}
          w={diameter}
          l={diameter}
          h={diameter}
        />
      </Fragment>
    );
  }

  if (element.type === "cylinder") {
    if (isWithinGeometry) {
      return <cylinderGeometry {...processElementProps(element.props)} />;
    }

    const diameter = (element.props.args[0] ?? 1) * 2;
    const height = element.props.args[2] ?? 1;

    return (
      <Fragment>
        <Cylinder {...omit(processElementProps(element.props), "position")}>
          <Material color={element.props.color} />
        </Cylinder>
        <Measurements
          measure={element.props.measure}
          w={diameter}
          l={diameter}
          h={height}
        />
      </Fragment>
    );
  }

  if (element.type === "cone") {
    if (isWithinGeometry) {
      return <coneGeometry {...processElementProps(element.props)} />;
    }

    const diameter = (element.props.args[0] ?? 1) * 2;
    const height = element.props.args[2] ?? 1;

    return (
      <Fragment>
        <Cone {...omit(processElementProps(element.props), "position")}>
          <Material color={element.props.color} />
        </Cone>
        <Measurements
          measure={element.props.measure}
          w={diameter}
          l={diameter}
          h={height}
        />
      </Fragment>
    );
  }

  if (element.type === "lathe") {
    if (isWithinGeometry) {
      return <latheGeometry {...processElementProps(element.props)} />;
    }

    return (
      <Fragment>
        <Lathe {...omit(processElementProps(element.props), "position")}>
          <Material color={element.props.color} side={2} />
        </Lathe>
      </Fragment>
    );
  }

  if (element.type === "extrude") {
    if (isWithinGeometry) {
      return <extrudeGeometry {...processElementProps(element.props)} />;
    }

    return (
      <Extrude {...omit(processElementProps(element.props), "position")}>
        <Material color={element.props.color} side={2} />
      </Extrude>
    );
  }

  if (element.type === "capsule") {
    if (isWithinGeometry) {
      return <capsuleGeometry {...processElementProps(element.props)} />;
    }

    return (
      <Capsule {...omit(processElementProps(element.props), "position")}>
        <Material color={element.props.color} side={2} />
      </Capsule>
    );
  }

  if (element.type === "torus") {
    if (isWithinGeometry) {
      return <torusGeometry {...processElementProps(element.props)} />;
    }

    return (
      <Torus {...omit(processElementProps(element.props), "position")}>
        <Material color={element.props.color} side={2} />
      </Torus>
    );
  }

  return (
    <Box>
      <meshStandardMaterial color={ERROR_COLOR[400]} opacity={0.5} />
    </Box>
  );
};

export const SceneElementRenderer: FC<Props> = memo(({ element, isRoot }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onPointerOver = useCallback((e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setIsHovered(true);
  }, []);
  const onPointerOut = useCallback((e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setIsHovered(false);
  }, []);

  const isWithinGeometry = useIsWithinGeometry();

  const setSceneRoot = useExportableRef(isRoot);

  if (isNil(element)) {
    return null;
  }

  if (element.type === "ignore") {
    return null;
  }

  if (
    isWithinGeometry ||
    element.type === "group" ||
    element.type === "mesh" ||
    element.type === "position" ||
    element.type === "rotation" ||
    element.type === "scale"
  ) {
    return <SceneElementDisplay element={element} isRoot={isRoot} />;
  }

  return (
    <Select enabled={isHovered}>
      <mesh
        ref={setSceneRoot}
        name={element.type}
        position={element.props.position}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
      >
        <SceneElementDisplay element={element} />
        {isHovered && (
          <Html>
            <SceneElementTooltip title={element.type}>
              <pre>{JSON5.stringify(element.props, null, 2)}</pre>
            </SceneElementTooltip>
          </Html>
        )}
      </mesh>
    </Select>
  );
});
