import { css } from "@emotion/react";
import { FC } from "react";
import { useAtomValue } from "jotai";
import { evaluatedState, transpiledState } from "../../state";
import { ERROR_COLOR } from "../../styles/globals";

const containerCss = css`
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  border-radius: 5px;
  border: 1px solid ${ERROR_COLOR[500]};
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  backdrop-filter: blur(10px) grayscale(75%);
`;

const failureCss = css`
  color: ${ERROR_COLOR[500]};
  display: flex;
  gap: 5px;
`;

export const SceneError: FC = () => {
  const transpiled = useAtomValue(transpiledState);
  const evaluated = useAtomValue(evaluatedState);

  if (transpiled.status === "success" && evaluated.status === "success") {
    return null;
  }

  return (
    <div css={containerCss}>
      {transpiled.status === "failure" && (
        <div data-code data-color={400} css={failureCss}>
          <div>{transpiled.error}</div>
        </div>
      )}

      {evaluated.status === "failure" && (
        <div data-code data-color={400} css={failureCss}>
          <div>{evaluated.error}</div>
        </div>
      )}
    </div>
  );
};
