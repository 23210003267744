import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { Coordinates, SceneElement, SceneElementChildren } from "../types";
import { processChildren } from "./helpers/processChildren";

export const position = (
  position: Coordinates,
  ...children: SceneElementChildren
): SceneElement => ({
  type: "position",
  props: { position },
  children: processChildren(children),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a position group. all descendants will be positioned relative to this group's position.
`;

const example1 = makeExample(`
;; This is logically the same
(position [1 0 1]
  ;; Geometries with the position
  (box { W: 2, H: 1, L: 2})
  (box { W: 1, H: 2, L: 1 }))

;; As this
(group {}
  (box { W: 2, H: 1, L: 2, position: [0 1 0] })
  (box { W: 1, H: 2, L: 1, position: [0 1 0] }))
`);

export const PositionDocumentation: DocNode = {
  name: "position",
  description,
  arguments: ["props", "children"],
  props: findPropsDeclaration("PositionProps"),
  tags: ["geometry", "coordinateSystem"],
  examples: [example1],
};
