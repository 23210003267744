import { css } from "@emotion/react";
import { ERROR_COLOR } from "../../styles/globals";

export const outputCss = (
  status: "success" | "failure",
  padding = "10px"
) => css`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: ${padding};
  white-space: pre;
  overflow: auto;

  ${status === "failure" &&
  css`
    color: ${ERROR_COLOR[500]};
  `}
`;
