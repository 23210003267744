import { Billboard, Line, Text } from "@react-three/drei";
import { FC } from "react";
import { Coordinates } from "../../types";
import { BASE_COLOR } from "../../styles/globals";

interface Props {
  size?: number;
}

const LABEL_SCALE: Coordinates = [0.05, 0.05, 0.05];
const LABEL_OFFSET = 1.05;
const COLOR = BASE_COLOR[200];

export const Axes: FC<Props> = ({ size = 1 }) => (
  <group scale={size}>
    <Line
      points={[
        [0, 0, 0],
        [1, 0, 0],
      ]}
      color={COLOR}
      lineWidth={2}
    />
    <group
      position={[LABEL_OFFSET, LABEL_OFFSET * LABEL_SCALE[1], 0]}
      scale={LABEL_SCALE}
    >
      <Billboard>
        <Text color={COLOR}>X</Text>
      </Billboard>
    </group>

    <Line
      points={[
        [0, 0, 0],
        [0, 1, 0],
      ]}
      color={COLOR}
      lineWidth={2}
    />
    <group position={[0, LABEL_OFFSET, 0]} scale={LABEL_SCALE}>
      <Billboard>
        <Text color={COLOR}>Y</Text>
      </Billboard>
    </group>

    <Line
      points={[
        [0, 0, 0],
        [0, 0, 1],
      ]}
      color={COLOR}
      lineWidth={2}
    />
    <group
      position={[0, LABEL_OFFSET * LABEL_SCALE[1], LABEL_OFFSET]}
      scale={LABEL_SCALE}
    >
      <Billboard>
        <Text color={COLOR} fontSize={1}>
          Z
        </Text>
      </Billboard>
    </group>
  </group>
);
