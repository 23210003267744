import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { LatheElementProps, LatheProps, SceneElement } from "../types";
import { pointsToVectors } from "./helpers/pointsToVectors";

const DEFAULT_SEGMENTS = 24;

const processProps = (props: LatheProps): LatheElementProps => ({
  ...props,
  args: [
    pointsToVectors(props.points),
    props.segments ?? props.args?.[1] ?? DEFAULT_SEGMENTS,
  ],
});

export const lathe = (props: LatheProps): SceneElement => ({
  type: "lathe",
  props: processProps(props),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a lathe geometry.
`;

const example1 = makeExample(`
(lathe { points: [[0 0], [1 0.2], [2 0.5]], color: 'indigo500 })
`);

export const LatheDocumentation: DocNode = {
  name: "lathe",
  description,
  arguments: [`props`],
  props: findPropsDeclaration("LatheProps"),
  tags: ["geometry", "primitive"],
  examples: [example1],
};
