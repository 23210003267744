import { atom } from "jotai";
import { assembliesState } from "./assembliesState";
import { uuid4 } from "../utils/uuid4";

export const duplicateAssemblyCommand = atom(
  null,
  (get, set, idToDuplicate: string, name: string) => {
    const assemblyToDuplicate = get(assembliesState).find(
      (assembly) => assembly.id === idToDuplicate
    );

    if (assemblyToDuplicate) {
      set(assembliesState, (assemblies) => [
        ...assemblies,
        { ...assemblyToDuplicate, id: uuid4(), name },
      ]);
    }
  }
);
