import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, FC, useCallback } from "react";
import {
  areTagFiltersActiveState,
  areTagsVisibleState,
  queryState,
} from "./state";
import { css } from "@emotion/react";
import { BASE_COLOR } from "../../styles/globals";
import Icon from "@mdi/react";
import { mdiFilter, mdiFilterOutline, mdiMagnify } from "@mdi/js";
import { useAtomCallback } from "jotai/utils";
import { FloatingBox } from "../controls/FloatingBox";
import { Tags } from "./Tags";

const containerCss = css`
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${BASE_COLOR[400]};
  display: flex;
  gap: 5px;
  padding: 5px 5px 5px 10px;
  align-items: center;
`;

const inputCss = css`
  border: none;
  width: 100%;
  padding: 0;
`;

const tagsMenuContainerCss = css`
  position: relative;
`;

export const Search: FC = () => {
  const [query, setQuery] = useAtom(queryState);

  const onChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const areTagsVisible = useAtomValue(areTagsVisibleState);
  const onToggleTags = useAtomCallback(
    useCallback((_get, set) => {
      set(areTagsVisibleState, (b) => !b);
    }, [])
  );
  const onCloseTags = useAtomCallback(
    useCallback((_get, set) => {
      set(areTagsVisibleState, false);
    }, [])
  );

  const areTagFiltersActive = useAtomValue(areTagFiltersActiveState);
  const tagButtonIconPath = areTagFiltersActive ? mdiFilter : mdiFilterOutline;

  return (
    <div css={containerCss}>
      <Icon path={mdiMagnify} size={0.7} />
      <input
        css={inputCss}
        placeholder="Start typing to search documentation..."
        type="text"
        value={query}
        onChange={onChangeQuery}
      />

      <div css={tagsMenuContainerCss}>
        <button type="button" data-borderless onClick={onToggleTags}>
          <Icon path={tagButtonIconPath} size={0.7} />
        </button>

        {areTagsVisible && (
          <FloatingBox onClickOutside={onCloseTags} maxHeight={300}>
            <Tags />
          </FloatingBox>
        )}
      </div>
    </div>
  );
};
