import { atom } from "jotai";
import { isError } from "lodash";
import stripAnsi from "strip-ansi";
// @ts-expect-error
import { sibilant } from "sibilant/lib/browser";

import { Transpiled } from "../types";

// @ts-expect-error
// eslint-disable-next-line import/no-webpack-loader-syntax
import sibilantPreamble from "!!raw-loader!../preamble/preamble.sibilant";
import { atomFamily } from "jotai/utils";
import { assemblyByIdState } from "./assemblyByIdState";

export const transpiledAssemblyState = atomFamily((assemblyId: string) =>
  atom<Transpiled>((get) => {
    try {
      const { instructions } = get(assemblyByIdState(assemblyId));

      const source = `${sibilantPreamble}\n\n${instructions}`;
      const output = sibilant.sibilize(source) as string;

      return { output, status: "success" };
    } catch (error: unknown) {
      if (isError(error)) {
        return { error: stripAnsi(error.message), status: "failure" };
      }
      return { error: "Unknown error", status: "failure" };
    }
  })
);
