import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { Assembly } from "../types";
import { assembliesState } from "./assembliesState";

export const assemblyByIdState = atomFamily((assemblyId: string) =>
  atom<Assembly>((get) => {
    const assemblies = get(assembliesState);
    const assembly = assemblies.find((assembly) => assembly.id === assemblyId);

    if (!assembly) {
      throw new Error(`Unable to find assembly by id ${assemblyId}`);
    }

    return assembly;
  })
);
