import { PrimitiveAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

export const persistentAtom = <TValue>(
  key: string,
  initialValue: TValue
): PrimitiveAtom<TValue> =>
  atomWithStorage<TValue>(
    key,
    initialValue,
    createJSONStorage<TValue>(() => window.localStorage),
    { unstable_getOnInit: true }
  );
