import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { Coordinates, SceneElement, SceneElementChildren } from "../types";
import { processChildren } from "./helpers/processChildren";

export const rotation = (
  rotation: Coordinates,
  ...children: SceneElementChildren
): SceneElement => ({
  type: "rotation",
  props: { rotation },
  children: processChildren(children),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a rotation group. all descendants will be rotated relative to this group's rotation.
`;

const example1 = makeExample(`
;; This is logically the same
(rotation [1 0 1]
  ;; Geometries with the rotation
  (box { W: 2, H: 1, L: 2})
  (box { W: 1, H: 2, L: 1 }))

;; As this
(group {}
  (box { W: 2, H: 1, L: 2, rotation: [0 1 0] })
  (box { W: 1, H: 2, L: 1, rotation: [0 1 0] }))
`);

export const RotationDocumentation: DocNode = {
  name: "rotation",
  description,
  arguments: ["props", "children"],
  props: findPropsDeclaration("RotationProps"),
  tags: ["geometry", "coordinateSystem"],
  examples: [example1],
};
