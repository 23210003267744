import { FC, PropsWithChildren, memo } from "react";
import { css } from "@emotion/react";
import { BASE_COLOR } from "../../styles/globals";

interface Props extends PropsWithChildren {
  title: string;
}

const containerCss = css`
  position: absolute;
  width: 0;
  height: 0;
  display: flex;
  align-items: center;
`;

const tooltipCss = css`
  position: relative;
  left: 100px;
  padding: 10px;
  border-radius: 5px;
  background: ${BASE_COLOR[800]};
  color: ${BASE_COLOR[50]};
  font-size: 14px;
  pointer-events: none;
  border: 1px solid ${BASE_COLOR[400]};
  min-width: 200px;
  width: fit-content;
  max-height: 400px;
  overflow: auto;
  gap: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.66);
`;

const titleCss = css`
  position: relative;
  display: flex;
  align-items: baseline;
  gap: 3px;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 3px;
    width: 100%;
    z-index: -1;
    border-bottom: 1px solid ${BASE_COLOR[500]};
  }
`;

export const SceneElementTooltip: FC<Props> = memo(({ title, children }) => {
  return (
    <div css={containerCss}>
      <div css={tooltipCss}>
        <strong data-code css={titleCss}>
          {title}
        </strong>
        {children}
      </div>
    </div>
  );
});
