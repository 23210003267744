import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import {
  GeometryElementProps,
  GeometryProps,
  SceneElement,
  SceneElementChildren,
} from "../types";
import { processChildren } from "./helpers/processChildren";

const processProps = (props: GeometryProps = {}): GeometryElementProps => ({
  ...props,
});

export const geometry = (
  props: GeometryProps,
  ...children: SceneElementChildren
): SceneElement => ({
  type: "geometry",
  props: processProps(props),
  children: processChildren(children),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a CSG (Constructive Solid Geometry) container.
`;

const example1 = makeExample(`
(geometry { position: [1 0 1] }
  (base {}
    (box { W: 1, H: 1, L: 1 }))
  (subtraction {}
    (box { W: 0.5, H: 1, L: 0.5 })))
`);

export const GeometryDocumentation: DocNode = {
  name: "geometry",
  description,
  arguments: ["props", "children"],
  props: findPropsDeclaration("GeometryProps"),
  tags: ["geometry", "CSG"],
  examples: [example1],
};
