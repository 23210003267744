import { isArray } from "lodash";
import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { Coordinates, SceneElement, SceneElementChildren } from "../types";
import { processChildren } from "./helpers/processChildren";

export const scale = (
  scale: number | Coordinates,
  ...children: SceneElementChildren
): SceneElement => ({
  type: "scale",
  props: { scale: isArray(scale) ? scale : [scale, scale, scale] },
  children: processChildren(children),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a scale group. all descendants will be scaled relative to this group's scale.
`;

const example1 = makeExample(`
;; This is logically the same
(scale [1 0 1]
  ;; Geometries with the scale
  (box { W: 2, H: 1, L: 2})
  (box { W: 1, H: 2, L: 1 }))

;; As this
(group {}
  (box { W: 2, H: 1, L: 2, scale: [0 1 0] })
  (box { W: 1, H: 2, L: 1, scale: [0 1 0] }))
`);

export const ScaleDocumentation: DocNode = {
  name: "scale",
  description,
  arguments: ["props", "children"],
  props: findPropsDeclaration("ScaleProps"),
  tags: ["geometry", "coordinateSystem"],
  examples: [example1],
};
