import { css } from "@emotion/react";
import { FC } from "react";
import { BASE_COLOR } from "../../styles/globals";

const containerCss = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  color: ${BASE_COLOR[200]};
  background-color: ${BASE_COLOR[800]};
  opacity: 0.5;
  font-size: 12px;
`;

export const Credits: FC = () => (
  <div css={containerCss}>
    &copy; FFuzion CAD is copyright{" "}
    <a href="https://cubicle6.com" target="_blank" rel="noreferrer">
      Caleb Peterson
    </a>
    . All rights reserved.
  </div>
);
