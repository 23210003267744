import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { Assembly } from "../types";
import { assembliesState } from "./assembliesState";

export const assemblyIdByNameState = atomFamily((name: string) =>
  atom<Assembly>((get) => {
    const assemblies = get(assembliesState);
    const result = assemblies.find((assembly) => assembly.name === name);

    if (!result) {
      throw new Error(`Could not find assembly by name "${name}"`);
    }

    return result;
  })
);
