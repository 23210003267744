import { useCallback } from "react";
import { setSceneRoot } from "./state";

export const useExportableRef = (isRoot: boolean | undefined) =>
  useCallback(
    (obj: THREE.Object3D | null) => {
      if (isRoot) {
        setSceneRoot(obj);
      }
    },
    [isRoot]
  );
