import { FC } from "react";
import { evaluatedState } from "../../state";
import { useAtomValue } from "jotai";
import { outputCss } from "../Output/styles";
import { EvaluatorLog } from "../../types";
import { css } from "@emotion/react";
import { isEmpty, isString } from "lodash";
import { Inspector } from "react-inspector";
import { INSPECTOR_THEME } from "../../styles/inspector";
import { BASE_COLOR, ERROR_COLOR } from "../../styles/globals";

const getLogColor = (level: EvaluatorLog["level"]) => {
  if (level === "debug") {
    return BASE_COLOR[500];
  }

  if (level === "error") {
    return ERROR_COLOR[500];
  }

  return BASE_COLOR[100];
};

const logCss = (level: "debug" | "log" | "error") => css`
  color: ${getLogColor(level)};
  width: 100%;
  min-width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
`;

const entriesCss = css`
  width: fit-content;
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
`;

const EvaluatorLogDisplay: FC<{ log: EvaluatorLog }> = ({ log }) => (
  <div css={logCss(log.level)}>
    <div>➞&nbsp;</div>
    <div css={entriesCss}>
      {log.args.map((entry, index) => (
        <div key={index}>
          {isString(entry) ? (
            entry
          ) : (
            <Inspector
              table={false}
              data={entry}
              // @ts-expect-error
              theme={INSPECTOR_THEME}
            />
          )}
        </div>
      ))}
    </div>
  </div>
);

const logsCss = css`
  width: 100%;
  max-width: 100%;
  display: flex;
  padding: 10px 5px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
`;

export const EvaluatorLogsDisplay: FC = () => {
  const evaluated = useAtomValue(evaluatedState);

  return (
    <div
      data-code
      data-color={400}
      css={[outputCss("success", "0px"), logsCss]}
    >
      {evaluated.logs.map((log, index) => (
        <EvaluatorLogDisplay log={log} key={index} />
      ))}

      {isEmpty(evaluated.logs) && (
        <div css={logCss("log")}>
          <em>Nothing was logged</em>
        </div>
      )}
    </div>
  );
};
