import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { CapsuleElementProps, CapsuleProps, SceneElement } from "../types";

const processProps = (props: CapsuleProps = {}): CapsuleElementProps => ({
  ...props,
  args: [
    props.R ?? props.args?.[0],
    props.L ?? props.args?.[1],
    props.capSegments ?? props.args?.[2],
    props.radialSegments ?? props.args?.[3],
  ],
});

export const capsule = (props: CapsuleProps): SceneElement => ({
  type: "capsule",
  props: processProps(props),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a capsule geometry.
`;

const example1 = makeExample(`
(capsule { R: 1, L: 1, color: 'indigo500 })
`);

export const CapsuleDocumentation: DocNode = {
  name: "capsule",
  description,
  arguments: [`props`],
  props: findPropsDeclaration("CapsuleProps"),
  tags: ["geometry", "primitive"],
  examples: [example1],
};
