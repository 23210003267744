import { isNumber, isObject } from "lodash";
import colors, { ColorFamilyName, ColorFamilyValue } from "./colors";

const DEFAULT_COLOR = colors.neutral[300];

export const getColor = (nameOrValue: string | number | undefined) => {
  if (nameOrValue === undefined) {
    return DEFAULT_COLOR;
  }

  if (isNumber(nameOrValue)) {
    return DEFAULT_COLOR;
  }

  let color;

  const match = nameOrValue.match(/([a-zA-Z]+)([0-9]+)/);
  if (match) {
    const [, text, numeric] = match;
    const family = colors[text as ColorFamilyName];
    color = isObject(family)
      ? family[parseInt(numeric, 10) as ColorFamilyValue]
      : family;
  }

  return color ?? nameOrValue;
};
