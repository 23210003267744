import { css } from "@emotion/react";
import { FC, useCallback, useState } from "react";
import { ACCENT_COLOR } from "../../styles/globals";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp, mdiOctahedron } from "@mdi/js";
import { useResetLayout } from "../Layout";
import { VIEWS } from "../Layout";
import { MosaicWindowKey } from "../Layout/types";
import { useSetAtom } from "jotai";
import { openViewCommand } from "../Layout/state";
import { getViewTitle } from "../../utils/getViewTitle";
import { useExportToSTL } from "../../exporting";
import { FloatingMenu } from "../controls/FloatingMenu";
import { smoothShadingEnabledState } from "../../state/renderingOptionsState";
import { Toggle } from "../controls/Toggle";
import { onChangeUnitsCommand } from "../../state/unitsState";

const containerCss = css`
  padding: 10px 7px 5px;
  height: min-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`;

const titleCss = css`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const controlsLayoutCss = css`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ViewMenuItem: FC<{ viewId: MosaicWindowKey; closeMenu: () => void }> = ({
  viewId,
  closeMenu,
}) => {
  const openView = useSetAtom(openViewCommand);

  const onClick = () => {
    closeMenu();
    openView(viewId);
  };

  return <button onClick={onClick}>Open {getViewTitle(viewId)}</button>;
};

const Menu: FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const resetLayout = useResetLayout();

  const onResetLayout = () => {
    closeMenu();
    resetLayout();
  };

  return (
    <FloatingMenu onCloseMenu={closeMenu}>
      <button onClick={onResetLayout}>Reset Views</button>

      <hr />

      {VIEWS.map((viewId) => (
        <ViewMenuItem key={viewId} viewId={viewId} closeMenu={closeMenu} />
      ))}
    </FloatingMenu>
  );
};

export const TopNav: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((b) => !b);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const menuIconPath = isMenuOpen ? mdiChevronUp : mdiChevronDown;

  const onChangeUnits = useSetAtom(onChangeUnitsCommand);
  const exportToSTL = useExportToSTL();

  return (
    <div css={containerCss}>
      <div css={titleCss}>
        <Icon path={mdiOctahedron} size={0.8} color={ACCENT_COLOR[400]} />
        <strong>FFuzion CAD</strong>&nbsp;
        <small data-muted>
          built with&nbsp;
          <a href="https://sibilant.org/" rel="noreferrer" target="_blank">
            sibilant
          </a>
          ,&nbsp;
          <a
            href="https://microsoft.github.io/monaco-editor/"
            rel="noreferrer"
            target="_blank"
          >
            monaco
          </a>
          ,&nbsp;and&nbsp;
          <a
            href="https://docs.pmnd.rs/react-three-fiber"
            rel="noreferrer"
            target="_blank"
          >
            react-three-fiber
          </a>
        </small>
      </div>

      <div css={controlsLayoutCss}>
        <select onChange={onChangeUnits}>
          <option value="mm">MM</option>
          <option value="inch">Inches</option>
        </select>

        <Toggle label="Smooth shading" state={smoothShadingEnabledState} />

        <button onClick={exportToSTL}>Export to STL</button>

        <button onClick={toggleMenu}>
          Views <Icon path={menuIconPath} size={0.7} />
        </button>

        {isMenuOpen && <Menu closeMenu={closeMenu} />}
      </div>
    </div>
  );
};
