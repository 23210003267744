import { EXAMPLES } from "../examples";
import { Assembly } from "../types";

export const DEFAULT_INSTRUCTIONS = `
(group { position: [0 1 0] }
  (box {
    L: 1 W: 1 H: 2
    position: [2 0 -2]
    color: 'rose500
    measure: true })
  (box {
    L: 1 W: 1 H: 2
    position: [-2 0 2]
    color: 'teal500
    measure: true })
  (geometry { position: [0 0 0] rotation: [0 45 0] color: 'emerald100 }
    (base {}
      (box { args: [3 1 3] }))
    (subtraction {}
      (sphere { R: 1.5}))
    (addition {}      
      (cylinder {
        args: [1, 1, 2]
        color: 'teal500 }))
    (subtraction {
        position: [0 0.5 0]
        rotation: [180 0 0] }
      (cone { rotation: [180 0 0] }))))
`.trim();

export const DEFAULT_ASSEMBLY: Assembly = {
  id: "demo",
  name: "Demo",
  instructions: DEFAULT_INSTRUCTIONS,
};

export const DEFAULT_ASSEMBLIES = [DEFAULT_ASSEMBLY, ...EXAMPLES];
