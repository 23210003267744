import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import {
  IntersectionElementProps,
  IntersectionProps,
  SceneElement,
  SceneElementChildren,
} from "../types";
import { processChildren } from "./helpers/processChildren";

const processProps = (
  props: IntersectionProps = {}
): IntersectionElementProps => ({
  ...props,
});

export const intersection = (
  props: IntersectionProps,
  ...children: SceneElementChildren
): SceneElement => ({
  type: "intersection",
  props: processProps(props),
  children: processChildren(children),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
make an intersection with a CSG (Constructive Solid Geometry) group's base shape.

the resulting shape will be the result of the intersection's children and the base shape.
`;

const example1 = makeExample(`
(geometry { position: [1 0 1] }
  (base {}
    (box { W: 1, H: 1, L: 1 }))
  (intersection {}
    (box { W: 0.5, H: 2, L: 0.5 })))
`);

export const IntersectionDocumentation: DocNode = {
  name: "intersection",
  description,
  arguments: ["props", "children"],
  props: findPropsDeclaration("IntersectionProps"),
  tags: ["geometry", "CSG"],
  examples: [example1],
};
