import * as THREE from "three";
import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { ExtrudeElementProps, ExtrudeProps, SceneElement } from "../types";
import { omit } from "lodash";
import { pointsToVectors } from "./helpers/pointsToVectors";

const processProps = (props: ExtrudeProps): ExtrudeElementProps => ({
  ...props,
  args: [
    props.points ? new THREE.Shape(pointsToVectors(props.points)) : props.shape,
    omit(props, "points", "shape"),
  ],
});

export const extrude = (props: ExtrudeProps): SceneElement => ({
  type: "extrude",
  props: processProps(props),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create an extrude geometry.
`;

const example1 = makeExample(`
(extrude { points: [[0 0], [1 0.2], [2 0.5]], color: 'indigo500 })
`);

export const ExtrudeDocumentation: DocNode = {
  name: "extrude",
  description,
  arguments: [`props`],
  props: findPropsDeclaration("ExtrudeProps", "UVGenerator"),
  tags: ["geometry", "primitive"],
  examples: [example1],
};
