import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { ConeElementProps, ConeProps, SceneElement } from "../types";

const processProps = (props: ConeProps = {}): ConeElementProps => ({
  ...props,
  args: [props.R ?? props.args?.[0], props.H ?? props.args?.[1]],
});

export const cone = (props: ConeProps): SceneElement => ({
  type: "cone",
  props: processProps(props),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a cone geometry.
`;

const example1 = makeExample(`
(cone { R: 1, H: 1, color: 'indigo500 })
`);

export const ConeDocumentation: DocNode = {
  name: "cone",
  description,
  arguments: [`props`],
  props: findPropsDeclaration("ConeProps"),
  tags: ["geometry", "primitive"],
  examples: [example1],
};
