import { FC, memo } from "react";
import { evaluatedState } from "../../state";
import { useAtomValue } from "jotai";
import { outputCss } from "../Output/styles";
import { Inspector } from "react-inspector";
import { INSPECTOR_THEME } from "../../styles/inspector";
import { nodeRenderer } from "./nodeRenderer";

export const EvaluatedOutput: FC = memo(() => {
  const evaluated = useAtomValue(evaluatedState);

  return (
    <div data-code data-color={400} css={outputCss(evaluated.status)}>
      {evaluated.status === "success" ? (
        <Inspector
          table={false}
          expandLevel={100}
          nodeRenderer={nodeRenderer}
          // @ts-expect-error
          theme={INSPECTOR_THEME}
          data={evaluated.output}
        />
      ) : (
        evaluated.error
      )}
    </div>
  );
});
