import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import { BASE_COLOR } from "../../styles/globals";

const pillCss = css`
  margin-right: 5px;
  padding: 2px 5px;
  border-radius: 3px;
  background-color: ${BASE_COLOR[700]};
  color: ${BASE_COLOR[100]};
  font-size: 12px;
`;

export const Pill: FC<PropsWithChildren> = ({ children }) => (
  <span css={pillCss}>{children}</span>
);
