import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import {
  DifferenceElementProps,
  DifferenceProps,
  SceneElement,
  SceneElementChildren,
} from "../types";
import { processChildren } from "./helpers/processChildren";

const processProps = (props: DifferenceProps = {}): DifferenceElementProps => ({
  ...props,
});

export const difference = (
  props: DifferenceProps,
  ...children: SceneElementChildren
): SceneElement => ({
  type: "difference",
  props: processProps(props),
  children: processChildren(children),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
make an difference with a CSG (Constructive Solid Geometry) group's base shape.

the resulting shape will be the result of the difference between the children and the base shape.
`;

const example1 = makeExample(`
(geometry { position: [1 0 1] }
  (base {}
    (box { W: 1, H: 1, L: 1 }))
  (difference {}
    (box { W: 0.5, H: 2, L: 0.5 })))
`);

export const DifferenceDocumentation: DocNode = {
  name: "difference",
  description,
  arguments: ["props", "children"],
  props: findPropsDeclaration("DifferenceProps"),
  tags: ["geometry", "CSG"],
  examples: [example1],
};
