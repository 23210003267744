import { DocNode } from "../docs/types";
import { findPropsDeclaration, makeExample } from "../docs/utils";
import { ObjectProps, SceneElement, SceneElementChildren } from "../types";
import { processChildren } from "./helpers/processChildren";

export const mesh = (
  props: ObjectProps,
  ...children: SceneElementChildren
): SceneElement => ({
  type: "mesh",
  props,
  children: processChildren(children),
});

// ----------------------------------------------------------------
// Documentation
// ----------------------------------------------------------------

const description = `
create a mesh geometry.
`;

const example1 = makeExample(`
(mesh { position: [1 0 1] }
  ;; Geometries contained within the mesh
  (box { W: 1, H: 1, L: 1, position: [0 1 0] })
  (box { W: 1, H: 1, L: 1, position: [0 -1 0] }))
`);

export const MeshDocumentation: DocNode = {
  name: "mesh",
  description,
  arguments: ["props", "children"],
  props: findPropsDeclaration("MeshProps"),
  tags: ["geometry", "composition"],
  examples: [example1],
};
