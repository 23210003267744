import { chromeDark } from "react-inspector";
import { ACCENT_COLOR, BASE_COLOR } from "./globals";

export const INSPECTOR_THEME = {
  ...chromeDark,
  BASE_BACKGROUND_COLOR: BASE_COLOR[900],
  BASE_COLOR: BASE_COLOR[100],
  OBJECT_NAME_COLOR: BASE_COLOR[100],
  OBJECT_VALUE_STRING_COLOR: ACCENT_COLOR[300],
  OBJECT_VALUE_NUMBER_COLOR: ACCENT_COLOR[600],
  OBJECT_VALUE_BOOLEAN_COLOR: ACCENT_COLOR[200],
  ARROW_COLOR: BASE_COLOR[300],
} as const;
