import { DocNode, Example, PropDeclaration } from "./types";
import GeometryTypedocs from "./preamble.json";
import { pick } from "lodash";

export const makeDocumentation = (docNode: DocNode): DocNode => ({
  ...docNode,
  description: docNode.description.trim(),
});

export const makeExample = (
  sibilant: string,
  javascript: string = ""
): Example => ({
  sibilant: sibilant.trim(),
  javascript: javascript.trim(),
});

type TypedocTupleElement = {
  type: "namedTupleMember";
  name: string;
  isOptional?: boolean;
};

type TypedocTypeNode =
  | {
      name?: string;
    }
  | {
      type: "tuple";
      elements: TypedocTupleElement[];
    };

const getTypeNodeElementAsString = (element: TypedocTupleElement): string =>
  `${element.name}${element.isOptional ? "?" : ""}`;

const getTypeNodeElementsAsString = (elements: TypedocTupleElement[]): string =>
  "[" +
  elements.map((element) => getTypeNodeElementAsString(element)).join(" ") +
  "]";

const getPropDeclarationType = (typeNode: TypedocTypeNode): string => {
  if ("name" in typeNode) {
    if (typeNode.name === "Coordinates") {
      return "[X-coord Y-coord Z-coord]";
    }

    if (typeNode.name === "Rotations") {
      return "[X-axis Y-axis Z-axis]";
    }

    return typeNode.name as string;
  }

  if ("type" in typeNode && typeNode.type === "tuple") {
    return getTypeNodeElementsAsString(typeNode.elements);
  }

  return "";
};

export const findPropsDeclaration = (
  query: string,
  ...propsToOmit: string[]
): PropDeclaration[] => {
  try {
    const typeNode = GeometryTypedocs.children.find(
      ({ name }) => name === query
    );

    if (typeNode) {
      return typeNode.children
        .map(
          (child) =>
            ({
              ...pick(child, "name"),
              type: getPropDeclarationType(child.type),
              isOptional: child.flags.isOptional,
            } as PropDeclaration)
        )
        .filter(
          (child) =>
            propsToOmit.length === 0 || !propsToOmit.includes(child.name)
        );
    }
  } catch (e) {
    console.warn(`Failed to find properties declaration for ${query}`);
    return [];
  }

  return [];
};
