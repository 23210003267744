import { ChangeEvent, FC } from "react";
import { WritableAtom, useAtom } from "jotai";
import { css } from "@emotion/react";

const labelCss = css`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Toggle: FC<{
  label: string;
  state: WritableAtom<boolean, [boolean], void>;
}> = ({ state, label }) => {
  const [value, setValue] = useAtom(state);
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
  };

  return (
    <label css={labelCss}>
      <input type="checkbox" checked={value} onChange={onChangeValue} />
      {label}
    </label>
  );
};
